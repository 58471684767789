import { Stack, Typography, Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import MovingIcon from "@mui/icons-material/Moving";
import image from "../../assets/image/New Project.png";

const AgentSection = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Stack
      sx={{
        marginTop: "6.375rem",
        marginBottom: "6.375rem",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: { xs: "1.5rem", md: "2rem" },
          marginBottom: "1.5rem",
        }}
      >
        See the AI Agent in Action
      </Typography>
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "33%" },
          marginBottom: "1.5rem",
        }}
      >
        <Typography>
          Discover how our AI agent engages users, automates tasks, and provides
          intelligent insights
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{ color: "white", background: "#0F0D1D" }}
      >
        See How It Works <MovingIcon />
      </Button>
      <Box sx={{ width: "100%", marginTop: "2rem", background: "#F0EDED" }}>
        <img
          src={image}
          alt="Description"
          style={{ width: "100%", height: "auto" }} // Make the image responsive
        />
      </Box>
    </Stack>
  );
};

export default AgentSection;
