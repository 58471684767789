import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles"; 
import tickicon from "../../assets/svg/Tick Icon.svg";

// Styled Components

const CardLayoutA = ({ card }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledCard isMdUp={isMdUp}>
      <StyledCardActionArea>
        <StyledCardMedia component="img" image={card.image} isMdUp={isMdUp} />
        <StyledCardContent>
          <TitleTypography>{card.title}</TitleTypography>
          <DescriptionTypography>{card.description}</DescriptionTypography>
          <Box sx={{ marginTop: "24px" }}>
            {(card.points || []).map((point, index) => (
              <Box
                display="flex"
                alignItems="center"
                marginBottom="18px"
                key={index}
              >
                <TickIcon src={tickicon} alt="Tick Icon" />
                <PointTypography>{point}</PointTypography>
              </Box>
            ))}
          </Box>
        </StyledCardContent>
      </StyledCardActionArea>
    </StyledCard>
  );
};

export default CardLayoutA;

const StyledCard = styled(Card)(({ theme, isMdUp }) => ({
  width: isMdUp ? "30.33%" : "100%",
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "none",
  margin: "8px",
  "&:hover": {
    boxShadow: "none",
  },
}));

const StyledCardActionArea = styled(CardActionArea)({
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
});

const StyledCardMedia = styled(CardMedia)(({ isMdUp }) => ({
  width: "100%",
  height: isMdUp ? "330px" : "232px",
}));

const StyledCardContent = styled(CardContent)({
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
});

const TitleTypography = styled(Typography)({
  fontSize: "1.75rem",
  fontWeight: 600,
});

const DescriptionTypography = styled(Typography)({
  fontSize: "1rem",
  fontWeight: "400",
  color: "#585858",
  paddingBottom: "24px",
  marginTop: "24px",
  borderBottom: "1px solid #E7E7E8",
});

const PointTypography = styled(Typography)({
  fontSize: "1rem",
  fontWeight: "400",
});

const TickIcon = styled("img")({
  width: 24,
  height: 24,
  marginRight: "8px",
});
