import React from "react";

const LinkedinSvg = ({ height = "11", width = "11", color = "gray" }) => {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_220_1412)">
        <path
          d="M10.9971 11V10.9996H10.9998V6.96531C10.9998 4.99173 10.575 3.47144 8.26771 3.47144C7.15854 3.47144 6.41421 4.0801 6.11033 4.65714H6.07825V3.65569H3.89062V10.9996H6.16854V7.36314C6.16854 6.40569 6.35004 5.47985 7.53575 5.47985C8.70404 5.47985 8.72146 6.57252 8.72146 7.42456V11H10.9971Z"
          fill={color}
        />
        <path d="M0.181641 3.65613H2.46231V11H0.181641V3.65613Z" fill={color} />
        <path
          d="M1.32092 0C0.591708 0 0 0.591708 0 1.32092C0 2.05013 0.591708 2.65421 1.32092 2.65421C2.05013 2.65421 2.64183 2.05013 2.64183 1.32092C2.64138 0.591708 2.04967 0 1.32092 0V0Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_220_1412">
          <rect width={width + ""} height={height + ""} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedinSvg;
