import React, { useState } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import phone from "../../../assets/svg/phone.svg";
import mail from "../../../assets/svg/mail.svg";
import location from "../../../assets/svg/location.svg";
import CustomButton from "../../../components/common/CustomButton";

const contactDetails = [
  {
    icon: <img src={phone} alt="phone" style={{ width: 24, height: 24 }} />,
    label: "Phone",
    value: "+91 9489032912",
  },
  {
    icon: <img src={mail} alt="mail" style={{ width: 24, height: 24 }} />,
    label: "Email",
    value: "hello@jseven.in",
  },
  {
    icon: (
      <img src={location} alt="location" style={{ width: 24, height: 24 }} />
    ),
    label: "Address",
    value: "11/54J, Albert Complex, PT Nagar, ALM, Tenkasi",
  },
];

const ContactAddress = () => {
  const [formData, setFormData] = useState({ email: "", subject: "", note: "" });

  const handleChange = (e) => {
    console.log(e);
    
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
console.log(formData)

  // const handleSubmit = async () => {
  //   try {
  //     await axios.post("http://localhost:4000/send-email",{ email:formData.email,subject:formData.subject,note:formData.note}); // Assumes `/send-email` route is set up in your backend
  //     console.log(formData);
  //     setFormData({ email: "", subject: "", note: "" });
      
  //     alert("Mail sent successfully");
  //   } catch (error) {
  //     alert(error.message);
  //   } 
  // };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4} justifyContent="center" boxShadow={"0px 1px 2px 0px #0000000F"}>
        {/* Contact Info Card */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 2, borderRadius: "16px" ,height:'395px'}}>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Get in Touch
              </Typography>
              {contactDetails.map((item, index) => (
                <Box key={index} mb={2}>
                  <Box display="flex" alignItems="center" mb={1}>
                    {item.icon}
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        color: "#0F0D1D",
                        ml: 1,
                        fontSize: "14px",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#8C8C8C",
                      fontSize: "14px",
                      pl: 4, // to align with the icon above
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 2, borderRadius: "16px" }}>
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Enter Your Email"
                  name="email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  color="#606FFF"
                />
                <TextField
                  fullWidth
                  label="Subject"
                  name="subject"
                  variant="outlined"
                  value={formData.subject}
                  onChange={handleChange}
                  color="#606FFF"
                />
                <TextField
                  fullWidth
                  label="Notes"
                  name="note"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={formData.note}
                  onChange={handleChange}
                  color="#606FFF"
                />
                <CustomButton width="20%">
                  Send
                </CustomButton>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactAddress;
