import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, Button, Snackbar, Alert } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const AdminPage = () => {
  const [blogContent, setBlogContent] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleBlogChange = (content) => setBlogContent(content);
  const handleTitleChange = (e) => setTitle(e.target.value);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null); // Clear the image state
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newBlog = {
      id: uuidv4(),
      title,
      content: blogContent,
      image,
      timestamp: new Date().toISOString(),
    };
    const existingBlogs = JSON.parse(localStorage.getItem("blogs")) || [];
    existingBlogs.push(newBlog);
    localStorage.setItem("blogs", JSON.stringify(existingBlogs));

    setOpenSnackbar(true);
    setBlogContent("");
    setTitle("");
    setImage(null);
  };

  const handleViewBlogs = () => {
    setOpenSnackbar(false);
    navigate("/bloglist");
  };

  return (
    <Box
      sx={{
        backgroundColor: "whitesmoke",
        minHeight: "100vh",
        p: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "50%",
          width: "100%",
        }}
      >
        <Stack spacing={3}>
          <Typography variant="h4" sx={{ color: "navy", fontWeight: "bold" }}>
            Post your blog!
          </Typography>
          <input
            type="text"
            placeholder="Blog Title"
            value={title}
            onChange={handleTitleChange}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              marginBottom: "16px",
            }}
          />
          {/* Custom File Upload Button */}
          <label htmlFor="upload-image">
            <input
              type="file"
              id="upload-image"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            <Button
              component="span"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "navy",
                color: "white",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "darkblue",
                },
              }}
            >
              Upload
            </Button>
          </label>

          {/* Display Image Preview and Remove Button */}
          {image && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <img
                src={image}
                alt="Uploaded Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleRemoveImage}
                sx={{
                  mt: 1,
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
              >
                Remove Image
              </Button>
            </Box>
          )}

          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <ReactQuill
                value={blogContent}
                onChange={handleBlogChange}
                placeholder="Write your blog here..."
                style={{ height: "300px", borderRadius: "8px" }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "navy",
                  "&:hover": { backgroundColor: "darkblue" },
                  alignSelf: "flex-start",
                  mt: 2,
                }}
              >
                Save Blog
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          action={
            <Button color="inherit" size="small" onClick={handleViewBlogs}>
              View Blogs
            </Button>
          }
          sx={{ backgroundColor: "navy", color: "white", fontWeight: "bold" }}
        >
          Blog saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminPage;
