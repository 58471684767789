import React from "react";

function DataEngineer({ color = "#384BFF", width = 24, height = 24 }) {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.667 2.71997C15.1803 2.71997 12.667 3.59997 12.667 5.2733V6.3333C13.1135 6.35419 13.5585 6.3987 14.0003 6.46664V5.2733C14.0003 4.83997 15.3937 4.04664 17.667 4.04664C19.9403 4.04664 21.3337 4.84664 21.3337 5.2733V12.16C21.3337 12.52 20.3603 13.12 18.7337 13.3133V14.6466C20.8203 14.4333 22.667 13.58 22.667 12.1466V5.2733C22.667 3.59997 20.1537 2.71997 17.667 2.71997Z"
        fill={color}
      />
      <path
        d="M2.66634 12.16V5.27332C2.66634 4.83998 4.05967 4.04665 6.33301 4.04665C8.60634 4.04665 9.99967 4.84665 9.99967 5.27332V6.46665C10.4415 6.39871 10.8865 6.35421 11.333 6.33332V5.27332C11.333 3.59332 8.81967 2.71332 6.33301 2.71332C3.84634 2.71332 1.33301 3.59998 1.33301 5.27332V12.16C1.33301 13.6 3.17967 14.4467 5.26634 14.6667V13.3333C3.63967 13.12 2.66634 12.52 2.66634 12.16Z"
        fill={color}
      />
      <path
        d="M12.0001 7.23334C8.7134 7.23334 6.2334 8.48667 6.2334 10.1533V18.36C6.2334 20.0267 8.7134 21.28 12.0001 21.28C15.2867 21.28 17.7667 20.0267 17.7667 18.36V10.1533C17.7667 8.48667 15.2867 7.23334 12.0001 7.23334ZM16.4334 12.3467C15.8667 13.0133 14.1534 13.68 12.0001 13.68C11.0994 13.6893 10.2018 13.5726 9.3334 13.3333V14.3067C10.2059 14.521 11.1016 14.6262 12.0001 14.62C13.5449 14.6827 15.0776 14.3231 16.4334 13.58V15.66C15.8667 16.3267 14.1534 16.9933 12.0001 16.9933C11.0991 17.0004 10.2015 16.8815 9.3334 16.64V17.6133C10.2059 17.8276 11.1016 17.9329 12.0001 17.9267C13.5449 17.9893 15.0776 17.6298 16.4334 16.8867V18.4133C16.4334 19.0467 14.6667 20 12.0001 20C9.3334 20 7.56673 19.0467 7.56673 18.4133V10.1533C7.56673 9.52 9.3334 8.56667 12.0001 8.56667C14.6667 8.56667 16.4334 9.52 16.4334 10.1533V12.3467Z"
        fill={color}
      />
    </svg>
  );
}

export default DataEngineer;
