import React from 'react'
import WrapperContainer from '../../components/common/WrapperContainer'
import ZepherBanner from './Banner'
import ZepherCard from './CardSection'
import FeatureSection from './FeatureSection'
import AgentSection from './AgentSection'

const Zepher = () => {
  return (
   <>
   <ZepherBanner/>
     <WrapperContainer>
        <ZepherCard/>
        <FeatureSection/>
        <AgentSection/>
     </WrapperContainer>
   </>
  )
}

export default Zepher