export const RoutesPath = {
  HOME: "/",
  ABOUT: "/about",
  AISERVICE: "/aiservice",
  MOBILEAPPSERVICE: "/mobileappservice",
  WEBSITEAPPSERVICE: "/websiteappservice",
  DATAENTRY: "/dataentry",
  DATAENGINEER: "/dataengineer",
  DATAANALYTICS: "/dataanalytics",
  UIUXSERVICE: "/uiuxservice",
  // BLOGPOSTDETAIL: "/blogpostdetail",
  // BLOGPOST:'/blogpost'
  BLOGADMIN: "/admin",
  BLOGDETAIL: "/blogdetails/:id",
  BLOGLIST: "/bloglist",
  BLOGPOSTDETAIL: "/blogpostdetail",
  BLOGPOST: "/blogpost",
  ContactUs: "/contactus",
  DIGITALMARKETING: "/digitalmarketingservice",
  TRACKGLE: "/trackgle",
  CAREERSDETAILS: "/careersdetails",
  CARZHEN: "/carzhen",
  ZEPHER: "/zepher",
  INTELLIVUZ: "/intellivuz",
  CAREERS: "/careers",
  PERSONALFORM: "/applynow",
};
