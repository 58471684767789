import { Stack } from "@mui/material";
import React from "react";
import icon6 from "../../../assets/svg/image10.svg";
import icon1 from "../../../assets/svg/image5.svg";
import icon2 from "../../../assets/svg/image6.svg";
import icon3 from "../../../assets/svg/image7.svg";
import icon4 from "../../../assets/svg/image8.svg";
import icon5 from "../../../assets/svg/image9.svg";
import BannerImage from "../../../assets/image/uiuxBanner.png";
import Banner from "../../../components/common/Banner";
import WrapperContainer from "../../../components/common/WrapperContainer";
import CardLayoutC from "../../../components/servicelayout/CardLayoutC.js";

const servicebanner = 
  {
    title: "Exceptional UI/UX Design Services to Elevate User Experience",
    subtitle:
      "A great user experience is essential for any digital product’s success. At J7 Technology, we specialize in creating user-centered designs that are not only visually appealing  but also intuitive and functional. Our UI/UX design services are aimed at delivering  seamless digital experiences that drive engagement and satisfaction. Here’s a  comprehensive list of the UI/UX design services we offer",
    BannerImage: BannerImage,
  }
;

const MobileService = [
  {
    title: "User Interface (UI) Design",
    description:
      "We create visually stunning and responsive interfaces that enhance user experience and reflect your brand identity.Our UI designs are crafted to be engaging, accessible, and easy to navigate, across all devices",
    points: [
      "Custom UI Design for Web and Mobile",
      "Responsive Design for Multiple Devices",
      "High-Fidelity Visual Mockups",
      "Branding and Style Guides",
      "Animation and Micro Interaction Design",
    ],
    icon: icon1, // Replace with the correct icon path
  },
  {
    title: "User Experience (UX) Design",
    description:
      "We focus on building user-friendly experiences that guide your users through your digital products. Our UX design services ensure every aspect of your product is optimized for usability, ensuring an enjoyable user journey.",
    points: [
      "Interaction Design",
      "Information Architecture (IA)",
      "Usability Testing and Analysis",
      "User Flow and Navigation Design",
      "UX Audit for Existing Products",
    ],
    icon: icon2, // Replace with the correct icon path
  },
  {
    title: "Wireframing and Prototyping",
    description:
      "Our wireframing and prototyping services allow you to visualize the structure and flow of your product before full development. This helps in testing and refining the design to ensure it meets both user and business needs.",
    points: [
      "Low-Fidelity Wireframes",
      "Interactive Prototypes",
      "Clickable Mockups",
      "User Flow Design",
      "Design System Architecture",
    ],
    icon: icon3, // Replace with the actual icon path
  },
  {
    title: "Design Systems and UI Kits",
    description:
      "We create comprehensive design systems and UI kits that ensure consistency across your  digital platforms. This allows for scalable and flexible design solutions, especially for  growing or multi-platform businesses.",
    points: [
      "Component Libraries",
      "Style Guides and Design Guidelines",
      "Color and Typography Systems",
      "Iconography and Visual Assets",
      "Reusable UI Elements for Scalability",
    ],
    icon: icon4, // Replace with the correct icon path
  },
  {
    title: "Interaction and Motion Design",
    description:
      "We add depth to your digital experiences with interactive elements and motion design that  captivate users. From subtle animations to engaging micro interactions, we make your  designs dynamic and engaging",
    points: [
      "AI-Powered Chatbots and Voice Assistants",
      "Internet of Things (IoT) Mobile App Integration",
      "Augmented Reality (AR) and Virtual Reality (VR) Apps",
      "Blockchain for Secure Transactions",
      "Cloud-Based Mobile Solutions",
    ],
    icon: icon5, // Replace with the correct icon path
  },
  {
    title: "Brand Identity and Visual Design",
    description:
      "Your brand’s visual identity is crucial for making a lasting impression. We help businesses establish their brand identity through thoughtful and cohesive visual design that aligns with their brand values.",
    points: [
      "Logo Design and Brand Identity",
      "Color Palette and Typography Design",
      "Visual Consistency Across Platforms",
      "Icon and Illustration Design",
      "Design Guidelines for Developers",
    ],
    icon: icon6, // Replace with the correct icon path
  },
];

const UIUX = () => {
  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />

      <WrapperContainer>
        <Stack direction="row" sx={{ flexWrap: "wrap" }}>
          {MobileService.map((service, key) => (
            <CardLayoutC
              title={service.title}
              description={service.description}
              points={service.points}
              icon={service.icon}
              key={key}
            />
          ))}
        </Stack>
      </WrapperContainer>
    </Stack>
  );
};

export default UIUX;
