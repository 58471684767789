import styled from "@emotion/styled";
import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../utils/Colors/Colors";
import { RoutesPath } from "../../routes/RoutesPath";
import { useNavigate } from "react-router-dom";

const BlogCart = ({ data,id,name,title,image,description,date,role }) => {
const navigate = useNavigate()
  const handleClick = () => {
    navigate(RoutesPath.BLOGPOSTDETAIL, {
      state: {
        id,
        name,
        date,
        title,
        image,
        description,role
      },
    });
  };
  return (
    <Container onClick={handleClick}>
      <ImageContainer>
        <Image src={data.image} />
        <DateShow>
          <Typography fontWeight={700} fontSize={24}>
            {data.date.split(" ")[0]}
          </Typography>
          <Typography fontWeight={700} fontSize={16}>
            {data.date.split(" ")[1]}
          </Typography>
        </DateShow>
      </ImageContainer>
      <ContentContainer>
        <IconsContainer>
          <IconsText>
            <Typography fontWeight={500} fontSize={16}>
              By {data.name}
            </Typography>
          </IconsText>
          <IconsText>
            <Typography fontWeight={500} fontSize={16}>
              {data.role}
            </Typography>
          </IconsText>
        </IconsContainer>

        <EllpiseText fontWeight={700} fontSize={20}>
          {data.title}
        </EllpiseText>
        <ReadMoreButton>
          <Typography fontSize={16} fontWeight={500}>
            Read More
          </Typography>
        </ReadMoreButton>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  height: "100%",
  borderRadius: 10,
  overflow: "hidden",
  backgroundColor: colors.white,
  boxShadow: `2px 8px 12px ${colors.lightgray}`,
}));
const ImageContainer = styled(Box)(() => ({
  width: "100%",
  aspectRatio: 2,
  position: "relative",
  overflow: "hidden",
}));
const EllpiseText = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const DateShow = styled(Box)(() => ({
  position: "absolute",
  top: "10%",
  left: "6%",
  backgroundColor: "#384BFF",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "& p": {
    color: "white",
  },
}));
const Image = styled("img")(() => ({
  height: "100%",
  width: "100%",
  display: "block",
}));
const ContentContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 10,
  padding: "0px 20px 20px 20px",
}));
const ReadMoreButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: 10,
}));
const IconsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));
const IconsText = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 10,
}));

export default BlogCart;
