import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import BgWhiteSpace from "../../assets/image/service-white-shape.png";
import BgBlueSpace from "../../assets/image/service-blue-shape.png";

const ServiceCart = ({ data, handleSelect = () => null }) => {
  return (
    <Container onClick={() => handleSelect(data)}>
      <HoverImage className="hide">{data?.svg}</HoverImage>
      <Image className="show">{data.svg}</Image>
      <Typography fontWeight={700} fontSize={20}>
        {data.title}
      </Typography>
      <EllpiseText>{data.subtitle}</EllpiseText>
      <ReadMoreButton onClick={() => handleSelect(data)}>
        Read More
      </ReadMoreButton>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 20,
  backgroundColor: "white",
  boxShadow: "0px 0px 20px lightgray",
  padding: 20,
  height: "100%",
  borderRadius: 10,
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",

  "&:hover .hide": {
    display: "none",
  },
  "&:hover .show": {
    display: "flex",
  },
  "& p": {
    color: "black",
    position: "relative",
    zIndex: 2,
    transition: ".3s", // Smooth transition
  },
  "&:hover p": {
    transition: ".3s", // Smooth transition
    color: "white",
  },
  "&::before": {
    position: "absolute",
    content: '""',
    inset: 0,
    background: "linear-gradient(180deg, #384BFF -23.85%, #6324B3 100%)",
    borderRadius: 10,
    transform: "translateY(-100%)", // Starts from the top
    transition: "all 0.3s ease-in-out", // Smooth transition
    zIndex: 1, // Ensures it's behind the content
    opacity: 0,
    pointerEvents: "none",
  },
  "&:hover::before": {
    transform: "translateY(0)", // Slides down on hover
    opacity: 1,
  },
  "&:hover button": {
    color: "white",
  },
}));

const ReadMoreButton = styled(Button)(() => ({
  borderRadius: 50,
  border: "1px solid lightgray",
  color: "black",
  padding: "10px 20px",
  zIndex: 2,
  transition: ".3s",
  "&:hover": {
    backgroundColor: "white",
    color: "black !important",
    transition: ".3s",
  },
}));
const Image = styled(Box)(() => ({
  backgroundImage: `url(${BgWhiteSpace})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  position: "relative",
  zIndex: 2,
  width: "5rem",
  height: "5rem",
  display: "none",
  justifyContent: "center",
  alignItems: "center",
}));
const HoverImage = styled(Box)(() => ({
  backgroundImage: `url(${BgBlueSpace})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  position: "relative",
  zIndex: 2,
  width: "5rem",
  height: "5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const EllpiseText = styled(Typography)(() => ({
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: 16,
}));

export default ServiceCart;
