import React from 'react'
import oneimg from '../../assets/image/one.png'
import twoimg from '../../assets/image/two.png'
import threeimg from '../../assets/image/three.png'
import { Stack, Typography,Box } from '@mui/material'
const CarazhenSectionE = () => {
    const boxesData = [
        {
          img: oneimg,
          title: 'Sign Up',
          description: 'Create a secure account in minutes',
        },
        {
          img: twoimg,
          title: 'Get Started',
          description: 'Start your journey with us today',
        },
        {
          img: threeimg,
          title: 'Stay Connected',
          description: 'Connect with your healthcare providers easily',
        },
      ];
  return (
   <Box sx={{marginBottom:'5.875rem'}}>
    <Typography sx={{fontSize:'2rem',fontWeight:'600',textAlign:'center',marginBottom:'5.875rem'}}>How It Works Section</Typography>
     <Stack direction='row' alignItems="flex-start" justifyContent="center" >
       {boxesData.map((box, index) => (
        <Box key={index} sx={{ width: '349px' }}>
          <img src={box.img} alt={box.title} style={{ width: '69px', height: '79px' }} />
          <Typography variant="h6" sx={{ fontWeight: '600', marginTop: '1rem' }}>
            {box.title}
          </Typography>
          <Typography sx={{ color: '#585858' }}>
            {box.description}
          </Typography>
        </Box>
      ))}
    </Stack>
   </Box>
  )
}

export default CarazhenSectionE