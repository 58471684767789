import { Stack, Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import image from "../../assets/image/zepherfeature.png";
const FeatureSection = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const points = [
    "Natural Language Processing: Understands user queries and provides intelligent responses",
    "Task Automation: Automates routine tasks, freeing up your team for higher-priority work",
    "Real-Time Analytics: Gain insights into user interactions, engagement, and automation performance",
    "Cross-Platform Compatibility:Delivers consistent experiences on web, mobile, and chat platforms",
    "Customizable Responses: Tailor the AI responses to reflect your brand and objectives",
    "Scalability: Seamlessly scales with your needs, supporting growth in user interactions.",
  ];
  return (
    <Box>
      <Typography sx={{fontSize:'2rem',fontWeight:'600',textAlign:'center'}}>Features Built to Enhance Automation and Engagement</Typography>
      <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }} // Stack vertically on small screens, horizontally on larger
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ marginTop: "2.813rem" }} // Margin applied to the container
    >
      <Box sx={{ width: { xs: "100%", md: "40%" } }}>
        <img
          src={image}
          alt="Description"
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "60%" },
          paddingLeft: { md: "5rem" },
          marginTop: { xs: "1rem", md: "2.813rem" },
        }}
      >
        <Stack spacing={4} sx={{ paddingY: "2rem" }}>
          {points.map((point, index) => (
            <Stack key={index} direction="row" alignItems="center">
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#018B95",
                  marginRight: "0.5rem",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "1rem",
                  color: "#585858",
                }}
              >
                {point}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Box>
    </Box>
  );
};

export default FeatureSection;
