import { Box, styled, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BlogCart from "../../components/common/BlogCart";
import WrapperContainer from "../../components/common/WrapperContainer";
import Snowflack from "../../assets/image/snowflack.png";
import AiEngineer from "../../assets/image/ai-eng.png";
import FullStackDev from "../../assets/image/fullstackdev.png";
import { colors } from "../../utils/Colors/Colors";
import { carouselSlider } from "../../utils/CuroselDynamic";
import { ResponsiveStyles } from "../../components/common/responsiveDimension";
import ETL from "../../assets/image/image3.png";
import { useNavigate } from "react-router-dom";
import { RoutesPath } from "../../routes/RoutesPath";
import { posts } from "../../utils/BlogData";

function SimpleSlider() {
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // fade: true,
    slidesToShow: 3,
    autoplay: true,
    slidesToScroll: carouselSlider(3, posts.length),
    initialSlide: 0,
    nextArrow: null,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // md (medium)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576, // sm (small)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // xs (extra small)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <WrapperContainer>
      <Container>
        <Box>
          <Heading>Read our Latest Blogs</Heading>
        </Box>
        <CurouselContainer>
          <Slider {...settings}>
            {posts.map((item, index) => (
              <CartContainer key={index}>
                <BlogCart
                  key={index}
                  data={item}
                  id={item.id}
                  name={item.name}
                  description={item.description}
                  image={item.image}
                  title={item.title}
                  date={item.date}
                  role={item.role}
                />
              </CartContainer>
            ))}
          </Slider>
        </CurouselContainer>
      </Container>
    </WrapperContainer>
  );
}

export default SimpleSlider;
const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  padding: "50px 0px",
}));
const CartContainer = styled(Box)(() => ({
  padding: "0px 10px",
  height: "100%",
  backgroundColor: colors.white,
}));
const CurouselContainer = styled(Box)(() => ({
  padding: "0px 10px",
  "& .slick-list": {
    backgroundColor: colors.lightgray,
  },
}));
const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 700,
  color: colors.DarkBlack,
  ...ResponsiveStyles({
    fontSize: {
      xs: 20,
      sm: 30,
      md: 40,
    },
  }),
}));
