import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

/**
 * Loader component to display a loading spinner.
 * @param {number} size - The size of the loader. Default is 40.
 * @param {string} color - The color of the loader. Default is "primary".
 * @returns {JSX.Element}
 */
const Loader = ({ size = 40, color = 'primary', text = 'Loading...' }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <CircularProgress size={size} color={color} />
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default Loader;
