import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo.png";
import { colors } from "../../utils/Colors/Colors";
import SideDrawer from "./Drawer";
import MenuList from "./Menu";
import { ResponsiveStyles, ScreenDimension } from "./responsiveDimension";
import WrapperContainer from "./WrapperContainer";
const Navbar = () => {
  const { MD } = ScreenDimension();
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastY, setLastY] = useState(null);
  const navigation = useNavigate();
  const [popOverOpen, setPopOverOpen] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation().pathname;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const list = [
    { name: "Home", navigate: "/" },
    {
      name: "Services",
      navigate: "/",
      menu: [
        {
          title: "Data Engineering",
          navigation: "/dataengineer",
        },
        {
          title: "AI Engineering",
          navigation: "/aiservice",
        },
        {
          title: "App Development",
          navigation: "/mobileappservice",
        },
        {
          title: "UI/UX Design",
          navigation: "/uiuxservice",
        },
        {
          title: "Website Development",
          navigation: "/websiteappservice",
        },
        {
          title: "Digital Marketing",
          navigation: null,
        },
        {
          title: "Data Analytics",
          navigation: "/dataanalytics",
        },
        {
          title: "Data Entry",
          navigation: "/dataentry",
        },
      ],
    },
    {
      name: "Products",
      navigate: "/",
      menu: [
        {
          title: "CareZhen",
          navigation: "/carzhen",
        },
        {
          title: "Zhepher",
          navigation: "/zepher",
        },
        {
          title: "Trackgle",
          navigation: "/trackgle",
        },
        {
          title: "Intellivuz",
          navigation: "/intellivuz",
        },
      ],
    },
    { name: "Blogs", navigate: "/blogpost", menu: [] },
    { name: "Careers", navigate: "/careers", menu: [] },
    { name: "Contact Us", navigate: "/contactus", menu: [] },
  ];

  const handleMouseOver = (event, value) => {
    if (!Boolean(value?.menu?.length)) return;
    setPopOverOpen(value?.menu);
    const mouseY = event.clientY;
    setLastY(mouseY);
    setAnchorEl(event.currentTarget);
  };
  const handleMouseLeave = (event) => {
    const mouseY = event.clientY;
    if (mouseY <= lastY) {
      setAnchorEl(null);
    }
  };

  const handleSelectMenu = (value) => {
    if (!value?.navigation || location === value?.navigation) return;
    navigation(value?.navigation);
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: "background.paper" }}
      >
        <WrapperContainer>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <ImageContainer onClick={() => navigation("/", { replace: true })}>
              <Image src={Logo} alt="logo" />
            </ImageContainer>
            {MD ? (
              <MenuItem>
                {list.map((value, index) => (
                  <MenuText
                    component={Link}
                    to={value.navigate}
                    key={index}
                    onMouseMove={(e) => handleMouseOver(e, value)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {value.name}
                  </MenuText>
                ))}
              </MenuItem>
            ) : (
              <IconButton color="black" edge="end" onClick={handleDrawerToggle}>
                <MenuOutlinedIcon />
              </IconButton>
            )}
          </Toolbar>
        </WrapperContainer>
      </AppBar>
      <SideDrawer open={mobileOpen} handleClose={handleDrawerToggle} />

      {Boolean(anchorEl) && (
        <MenuList
          list={popOverOpen}
          displayKey="title"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClose={() => setAnchorEl(null)}
          handleSelect={handleSelectMenu}
        />
      )}
    </>
  );
};

export default Navbar;
const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "20%",
}));
const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  ...ResponsiveStyles({
    width: { xs: "30%", md: "15%" },
  }),
}));
const MenuItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...ResponsiveStyles({
    width: { xs: "20%", md: "50%" },
    justifyContent: {
      xs: "end",
      md: "space-between",
    },
  }),
}));
const MenuText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: colors.DarkBlack,
  textDecoration: "none",
  transition: "all 0.3s linear",
  fontWeight: 600,
  position: "relative",
  zIndex: 2,
  ":hover": {
    transition: "all 0.3s linear",
    color: colors.blue,
  },
  ":hover::before": {
    transition: "all 0.2s linear",
    width: "100%",
  },
  "::before": {
    position: "absolute",
    transition: "all 0.3s linear",
    content: '" "',
    height: 2,
    borderRadius: 5,
    width: "0%",
    bottom: 0,
    left: 0,
    backgroundColor: colors.blue,
  },
}));
const MenuIcon = styled(Box)(({ theme }) => ({
  color: "#384bff",
  cursor: "pointer",
  fontSize: 30,
}));
