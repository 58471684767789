import React from "react";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/AIBanner.png";
import { Stack, Box } from "@mui/material";
import WrapperContainer from "../../../components/common/WrapperContainer";
import CardLayoutA from "../../../components/servicelayout/CardLayoutA";
import card1 from "../../../assets/image/DEn1.png";
import card2 from "../../../assets/image/DEn2.png";
import card3 from "../../../assets/image/DEn3.png";
import card4 from "../../../assets/image/DEn4.png";
import card5 from "../../../assets/image/DEn5.png";
import card6 from "../../../assets/image/DEn6.png";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
const DataEntryService = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const servicebanner = {
    title:
      "   Precision at Scale: Comprehensive Data Entry Services to Elevate Your Business ",
    subtitle:
      "In today's fast-paced business environment, accurate and efficient data entry is critical to making informed decisions and driving operational excellence. At J7 Technology we provide a comprehensive suite of data entry services designed to streamline your processes, enhance data accuracy, and unlock the true potential of your information. Here’s a detailed overview of our offerings, equipped with advanced technologies and methodologies to meet your business needs. ",
      BannerImage: BannerImage ,
  };
  // const cardsData = [
  //   {
  //     title: "User Interface (UI) Design",
  //     description:
  //       "We create visually stunning and responsive interfaces that enhance user experience and reflect your brand identity. Our UI designs are crafted to be engaging, accessible, and easy to navigate, across all devices.",
  //     image: card1,
  //     points: [
  //       "Custom UI Design for Web and Mobile",
  //       "Responsive Design for Multiple Devices",
  //       "High-Fidelity Visual Mockups",
  //       "Branding and Style Guides",
  //       "Animation and Micro interaction Design",
  //     ],
  //   },
  //   {
  //     title: "User Experience (UX) Design",
  //     description:
  //       "We focus on building user-friendly experiences that guide your users through seamless  interactions. Our UX design process ensures that every element of your digital product is  optimized for usability, ensuring an enjoyable user journey.",
  //     image: card2,
  //     points: [
  //       "Interaction Designs",
  //       " Information Architecture (IA)",
  //       " Usability Testing and Analysis",
  //       "Task Flow and Navigation Design",
  //       " UX Audit for Existing Products",
  //     ],
  //   },

  //   {
  //     title: "Wireframing and Prototyping",
  //     description:
  //       " Our wireframing and prototyping services allow you to visualize the structure and flow of  your product before full development. This helps in iterating and refining the design to  ensure it meets both user and business needs",
  //     image: card3,
  //     points: [
  //       " Low-Fidelity Wireframes",
  //       "Interactive Prototypes",
  //       "Clickable Mockups",
  //       "User Flow Design",
  //       "Design System Architecture",
  //     ],
  //   },
  //   {
  //     title: "Design Systems and UI Kits",
  //     description:
  //       " We create comprehensive design systems and UI kits that ensure consistency across your  digital platforms. This allows for scalable and flexible design solutions, especially for  growing or multi-platform businesses",
  //     image: card4,
  //     points: [
  //       "Component Libraries",
  //       "Style Guides and Design Guidelines",
  //       "Color and Typography Systems",
  //       "Iconography and Visual Assets",
  //       "Reusable UI Elements for Scalability",
  //     ],
  //   },
  //   {
  //     title: "Interaction and Motion Design",
  //     description:
  //       "We add depth to your digital experiences with interactive elements and motion design that  captivate users. From subtle animations to engaging micro interactions, we make your  designs dynamic and engaging",
  //     image: card5,
  //     points: [
  //       "Micro interactions for Web and Mobile",
  //       "Animation Design for User Feedback",
  //       "Interactive Components and Widgets",
  //       "Transitions and Hover Effects",
  //       " Storytelling with Motion Graphics",
  //     ],
  //   },
  //   {
  //     title: "Brand Identity and Visual Design",
  //     description:
  //       "Your brand’s visual identity is crucial for making a lasting impression. We help businesses  establish or refine their brand identity through thoughtful and cohesive visual design that  aligns with their brand values",
  //     image: card6,
  //     points: [
  //       "Logo Design and Brand Identity",
  //       "Color Palette and Typography Design",
  //       "Visual Consistency Across Platforms",
  //       "Icon and Illustration Design",
  //       "Design Guidelines for Developers",
  //     ],
  //   },
  //   // {
  //   //   title: "Mobile-First and Responsive Design",
  //   //   description:
  //   //     "With users accessing content from various devices, we ensure your designs are optimized  for any screen size. Our mobile-first approach ensures that mobile users have a seamless  experience, while desktop users enjoy a rich, responsive interface",
  //   //   image: card7,
  //   //   points: [
  //   //     "Mobile-First Design Strategy",
  //   //     "Responsive Web Design for All Screen Sizes",
  //   //     "Adaptive Layouts and Navigation",
  //   //     "Progressive Web App (PWA) Design",
  //   //     "Accessibility Standards Compliance",
  //   //   ],
  //   // },
  // ];
  const cardsData = [
    {
      title: "Manual Data Entry ",
      description: ` Our expert team specializes in meticulous manual data entry, ensuring that every detail is captured with precision. We handle large volumes of data, transforming it into actionable insights. `,
      image: card1,
      points: [
        "Data Input and Migration  ",
        "Quality Assurance Checks ",
        `Custom Data Entry Solutions`,
      ],
    },
    {
      title: "Automated Data Entry ",
      description: `Leverage the power of automation to enhance efficiency and reduce human error. Our automated data entry solutions accelerate the data collection process without sacrificing quality. `,
      image: card2,
      points: [
        "Optical Character Recognition (OCR) ",
        "Data Extraction Tools ",
        `Integration with Business Systems `,
      ],
    },
    {
      title: "Data Processing and Validation ",
      description:
        "Transform raw data into meaningful information with our data processing and validation services. We ensure that your data is accurate, complete, and ready for analysis. ",
      image: card3,
      points: [
        `Data Cleansing  `,
        `Data Enrichment  `,
        "Validation Protocols  ",
      ],
    },
    {
      title: "Data Conversion Services ",
      description:
        "Convert your data into different formats seamlessly. Our data conversion services enhance accessibility and usability across platforms. ",
      image: card4,
      points: [
        "File Format Conversion ",
        "Database Migration ",
        "Legacy System Conversion ",
      ],
    },
    {
      title: "Online Data Entry ",
      description:
        "Capitalize on the digital landscape with our online data entry services. We manage data entry tasks for eCommerce, CRMs, and more, enhancing your operational efficiency. ",
      image: card5,
      points: [
        `E-commerce Product Data Entry `,
        `CRM Data Management  `,
        `Online Survey Data Entry `,
      ],
    },
    {
      title: "Document Management ",
      description:
        "Enhance your document organization with our efficient document management services. We help digitize and categorize your documents for easy retrieval and compliance. ",
      image: card6,
      points: [
        `Document Scanning and Digitization`,
        "Indexing and Categorization  ",
        "Compliance Support ",
      ],
    },
  ];
  return (
    <Stack>
     <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />
      <WrapperContainer>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "80px 16px",
          }}
        >
          {cardsData.map((card, index) => (
            <CardLayoutA key={index} card={card} isMdUp={isMdUp} />
          ))}
        </Box>
      </WrapperContainer>
    </Stack>
  );
};

export default DataEntryService;
