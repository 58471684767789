import { Grid, Card, Container } from "@mui/material";

const ContactUsMap = () => {
  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      {/* Map Section */}
      <Grid item xs={12}>
        <Card sx={{ mt: 4 }}>
          <iframe
            title="Location Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1169.956267125502!2d77.4848069!3d8.8688878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0423c55442b05f%3A0x9938af9d19992a5!2sJ7%20Technology%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1634567890123!5m2!1sen!2sin"
            width="100%"
            height="700"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </Card>
      </Grid>
    </Container>
  );
};

export default ContactUsMap;
