import styled from "@emotion/styled";
import { Box, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { colors } from "../../utils/Colors/Colors";

const MenuList = ({
  list = [],
  anchorEl,
  open = false,
  handleClose = () => null,
  handleSelect = () => null,
  displayKey = "",
}) => {
  const listRef = useRef(null);
  const handleMouseMove = (event) => {
    const mouseY = event.clientY;
    const mouseX = event.clientX;
    const { top, left } = anchorEl?.getBoundingClientRect();
    const width = listRef?.current?.clientWidth + left;
    const height = listRef?.current?.clientHeight;
    const sum = top + height + 40;
    if (mouseY > sum || left >= mouseX || width <= mouseX) {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleClose);
    return () => {
      window.removeEventListener("scroll", handleClose);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onMouseMove={handleMouseMove}
      onClose={handleClose}
      disableScrollLock
      sx={{ pointerEvents: "all", zIndex: 1 }}
      hideBackdrop
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: `drop-shadow(0px 2px 8px ${colors.gray})`,
            mt: 5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <MenuContainer ref={listRef}>
        {list?.map((value, index) => (
          <MenuItem
            key={index}
            value={value}
            onClick={() => handleSelect(value)}
            // sx={{fontSize:'1.5rem'}}
          >
            {value?.[displayKey]}
          </MenuItem>
        ))}
      </MenuContainer>
    </Menu>
  );
};

const MenuContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
}));

export default MenuList;
