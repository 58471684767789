import React from "react";

function MobileAppDev({ color = "#384BFF", width = 14, height = 20 }) {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.38 10.5H6C5.82 10.5 5.67267 10.5577 5.558 10.673C5.44267 10.7883 5.385 10.936 5.385 11.116V13.616C5.385 13.7573 5.337 13.876 5.241 13.972C5.145 14.068 5.026 14.116 4.884 14.116C4.742 14.116 4.62333 14.068 4.528 13.972C4.43267 13.876 4.38467 13.7573 4.384 13.616V11.116C4.384 10.6713 4.54233 10.291 4.859 9.975C5.17567 9.65833 5.556 9.5 6 9.5H12.38L10.64 7.754C10.5467 7.66067 10.4967 7.547 10.49 7.413C10.4833 7.27833 10.5367 7.15467 10.65 7.042C10.754 6.93867 10.8707 6.887 11 6.887C11.1293 6.887 11.2473 6.94033 11.354 7.047L13.742 9.435C13.904 9.59633 13.985 9.78467 13.985 10C13.985 10.2153 13.904 10.404 13.742 10.566L11.354 12.954C11.2607 13.0473 11.147 13.0983 11.013 13.107C10.879 13.1157 10.7553 13.0633 10.642 12.95C10.5387 12.846 10.486 12.7303 10.484 12.603C10.482 12.4757 10.5343 12.3567 10.641 12.246L12.38 10.5ZM1.617 20C1.17233 20 0.792 19.8417 0.476 19.525C0.16 19.2083 0.00133333 18.8287 0 18.386V1.615C0 1.171 0.158333 0.791 0.475 0.475C0.791667 0.159 1.17167 0.000666667 1.615 0H10.385C10.829 0 11.209 0.158333 11.525 0.475C11.841 0.791667 11.9993 1.17133 12 1.614V4.422C12 4.564 11.952 4.68267 11.856 4.778C11.76 4.87333 11.641 4.92133 11.499 4.922C11.357 4.92267 11.2383 4.87467 11.143 4.778C11.0477 4.68133 11 4.563 11 4.423V3.5H1V16.5H11V15.577C11 15.435 11.048 15.3163 11.144 15.221C11.24 15.1257 11.359 15.0777 11.501 15.077C11.643 15.0763 11.7617 15.1243 11.857 15.221C11.9523 15.3177 12 15.4363 12 15.577V18.384C12 18.8287 11.8417 19.209 11.525 19.525C11.2083 19.841 10.8283 19.9993 10.385 20H1.617Z"
        fill={color}
      />
    </svg>
  );
}

export default MobileAppDev;
