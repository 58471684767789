import React from "react";
import { Stack, Box, Typography, Button,useMediaQuery } from "@mui/material";
import image from "../../assets/image/woman.png";
const CarezhenFooter = () => {
    const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <Stack direction="row" backgroundColor="#003A3F" sx={{ marginBottom:'2rem' }}>
    <Box color="white" sx={{ padding: "3rem", zIndex: 1 }}>
        <Typography sx={{ fontSize: { xs: "2rem", md: "3rem" }, fontWeight: "600" }}>
            Ready to Transform Healthcare?
        </Typography>
        <Typography sx={{ fontSize: { xs: "1.2rem", md: "1.725rem" }, fontWeight: "500" }}>
            Sign up today to experience a new era of connected and compassionate healthcare
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: "#018B95", marginTop: '1rem' }}>
            Get Started Now
        </Button>
    </Box>
    {isMdUp && (
        <Box
        sx={{
            position: "absolute",
            right: 0,
            transform: isMdUp ? 'translateY(-135px)' : 'translateY(-50px)', // Adjust for smaller screens
            zIndex: 0, // Ensure the image is behind the text
        }}
    >
        <img
            src={image}
            style={{ height: isMdUp ? '400px' : '250px', width: "auto" }} // Adjust height for smaller screens
            alt="Description"
        />
    </Box>
    )}
    
</Stack>
  );
};

export default CarezhenFooter;
