import { Box, styled, Typography } from "@mui/material";
import React from "react";
import Backgroundpng from "../../assets/image/Home-service-bg.jpg";
import WrapperContainer from "../../components/common/WrapperContainer";
import { colors } from "../../utils/Colors/Colors";
import ServiceCart from "../../components/common/ServiceCart";
import { ResponsiveStyles } from "../../components/common/responsiveDimension";
import { useNavigate } from "react-router-dom";
import DataEngineer from "../../assets/svg/DataEngineer";
import AiEngineer from "../../assets/svg/AiEngineer";
import Digital from "../../assets/svg/Digital";
import UiUx from "../../assets/svg/UiUx";
import WebDevelopment from "../../assets/svg/WebDevelopment";
import DataAnalttics from "../../assets/svg/DataAnalttics";
import DataEntry from "../../assets/svg/DataEntry";
import MobileAppDev from "../../assets/svg/MobileAppDev";
import ReactGA from "react-ga4";

function ServiceBanner() {
  const Navigation = useNavigate();
  const ServiceData = [
    {
      svg: <DataEngineer />,
      title: "Data Engineering",
      subtitle:
        "Empower your organization with scalable data architectures and optimized data",
      navigation: "/dataengineer",
    },
    {
      svg: <AiEngineer />,
      title: "AI Engineering",
      subtitle:
        "Unlock new opportunities for efficiency and growth with our bespoke…",
      navigation: "/aiservice",
    },
    {
      svg: <MobileAppDev />,
      title: "App Development",
      subtitle: "Seamlessly crafted, high-performance apps for iOS and Android",
      navigation: "/mobileappservice",
    },
    {
      svg: <UiUx />,
      title: "UI/UX Design",
      subtitle:
        "Leverage our transformative designs to boost user satisfaction, drive engagement,…",
      navigation: "/uiuxservice",
    },
    {
      svg: <WebDevelopment />,
      title: "Website Development",
      subtitle:
        "Responsive, dynamic websites expertly crafted to reflect your brand and…",
      navigation: "/websiteappservice",
    },
    {
      svg: <Digital />,
      title: "Digital Marketing",
      subtitle:
        "Tailored digital marketing strategies designed to amplify your brand, attract…",
      navigation: '/digitalmarketingservice',
    },
    {
      svg: <DataAnalttics />,
      title: "Data Analytics",
      subtitle:
        "Get real-time insights through dynamic dashboards and visually compelling reports,…",
      navigation: "/dataanalytics",
    },
    {
      svg: <DataEntry />,
      title: "Data Entry",
      subtitle:
        "Delivering precise and efficient data entry solutions tailored to your business need",
      navigation: "/dataentry",
    },
  ];
  const handleNavigation = (item) => {
    if (item.navigation) {
      ReactGA.event({
        category: "Services",
        action: "Click",
        label: item.title,
        value: item.navigation,
      });
      Navigation(item.navigation);
    }
  };
  return (
    <Container id="services">
      <WrapperContainer>
        <SubContainer>
          <TobBox>
            <Typography fontSize={18} fontWeight={500} color={colors.blue}>
              IT SERVICES
            </Typography>
            <Heading>
              Empowering IT Solutions Through Cutting-Edge Technology
            </Heading>
          </TobBox>
          <BottomBox>
            {ServiceData.map((item, index) => (
              <CartContainer key={index}>
                <ServiceCart data={item} handleSelect={handleNavigation} />
              </CartContainer>
            ))}
          </BottomBox>
        </SubContainer>
      </WrapperContainer>
    </Container>
  );
}

export default ServiceBanner;
const Container = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Backgroundpng})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
}));
const SubContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  rowGap: "24px",
  padding: "100px 0px",
}));
const TobBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
}));
const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 700,
  color: colors.DarkBlack,
  ...ResponsiveStyles({
    fontSize: {
      xs: 20,
      sm: 30,
      md: 40,
    },
  }),
}));
const BottomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));
const CartContainer = styled(Box)(({ theme }) => ({
  marginTop: 20,
  ...ResponsiveStyles({
    width: { xs: "100%", sm: "50%", md: "33.33%", lg: "25%" },
    padding: { xs: 0, sm: "0px 10px" },
  }),
}));
