import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import routes from "./routes/routes";
import ThemeProvider from "./theme/ThemeProvider";
import "./App.css";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

const App = () => {
  const TRACKING_ID = process.env.REACT_APP_GA_ID;
  console.log("TRACKING_ID", TRACKING_ID);
  ReactGA?.initialize(TRACKING_ID);

  return (
    <ThemeProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jseven Technology Solutions Pvt Ltd.</title>
        <meta name="Technology Solutions" content="Be it Solution Provider" />
        <link
          rel="Service"
          href="https://main.dnmm5bcbxgwn6.amplifyapp.com/aiservice"
        />
      </Helmet>
      <Router>
        <AppRoutes routes={routes} />
      </Router>
    </ThemeProvider>
  );
};

export default App;
