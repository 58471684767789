import { Box, styled, Typography } from "@mui/material";
import React from "react";
import Circle from "../../assets/image/dot-shape.png";
import Backgroundpng from "../../assets/image/hero-bg.jpg";
import Hero from "../../assets/image/hero.png";
import LineShape from "../../assets/image/line-shape-1.png";
import leftShape from "../../assets/image/mask-shape.png";
import Switch from "../../assets/svg/switch";
import AnimationButton from "../../components/common/AnimationButton";
import {
  ResponsiveStyles,
  ScreenDimension,
} from "../../components/common/responsiveDimension";
import WrapperContainer from "../../components/common/WrapperContainer";
import { colors } from "../../utils/Colors/Colors";

function Banner() {
  const { MD } = ScreenDimension();
  const scrollToServices = () => {
    const servicesSection = document.getElementById("services");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Container>
      <LineShade>
        <Image src={LineShape} alt="line" />
      </LineShade>
      {MD && (
        <>
          <CircleShape>
            <Image src={Circle} alt="line" />
          </CircleShape>
          <LeftShade>
            <Image src={leftShape} alt="background" />
          </LeftShade>
        </>
      )}
      <WrapperContainer>
        <SubContainer>
          <RightSideBox>
            <TextContainer>
              <Switch />
              <Typography fontSize={22} color={colors.blue}>
                Best IT Solution Provider
              </Typography>
            </TextContainer>
            <BoldText>Engineering Excellence, Driving Innovation</BoldText>
            <Typography fontSize={18} color={colors.lightBlack}>
              Our mission is to empower businesses with innovative, data-driven
              solutions, ensuring efficiency, scalability, and a competitive
              edge.
            </Typography>
            <Box mt={3} display={"flex"} alignItems={"center"} gap={10}>
              {/* <BlinkingPlayButton /> */}
              <AnimationButton
                title="Explore More"
                innerColor={colors?.black}
                outerColor={colors.blue}
                handleClick={scrollToServices}
              />
            </Box>
          </RightSideBox>
          <LeftImageContainer>
            <Image src={Hero} alt="hero" />
          </LeftImageContainer>
        </SubContainer>
      </WrapperContainer>
    </Container>
  );
}

export default Banner;
const Container = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Backgroundpng})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  zIndex: 1,
  position: "relative",
}));
const RightSideBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "65%",
  gap: 20,
  justifyContent: "center",
  alignItems: "flex-start",
  ...ResponsiveStyles({
    width: { xs: "100%", md: "65%" },
  }),
}));
const SubContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
}));
const LineShade = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  left: "0%",
  zIndex: -1,
}));
const CircleShape = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "2%",
  left: "15%",
  zIndex: -1,
  aspectRatio: 1,
  width: "50%",
}));
const LeftShade = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: "0%",
  right: "0%",
  zIndex: -1,
  width: "50%",
  aspectRatio: 1,
}));
const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "block",
}));
const LeftImageContainer = styled(Box)(({ theme }) => ({
  ...ResponsiveStyles({
    width: { xs: "100%", md: "35%" },
  }),
}));
const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "8px",
  alignItems: "center",
  mb: 3,
}));
const BoldText = styled(Typography)(({ theme }) => ({
  mb: 3,
  fontWeight: 700,
  color: colors.DarkBlack,
  lineHeight: 1.2,
  ...ResponsiveStyles({
    fontSize: { xs: 30, sm: 50, md: 78 },
  }),
}));
