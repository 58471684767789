import { Stack, Typography,Box,useMediaQuery } from "@mui/material";
import React from "react";
import image1 from '../../assets/image/zephercard1.png'
import image2 from '../../assets/image/zephercard2.png'
import image3 from '../../assets/image/zephercard3.png'
const ZepherCard = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const cardData = [
        {
          img: image1,
          title: 'Effortless Automation',
          description: 'Streamline tasks and workflows with intelligent, hands-free automation',
        },
        {
          img: image2,
          title: 'Enhanced User Engagement',
          description: 'Deliver personalized responses and solutions to keep users engaged',
        },
        {
          img: image3,
          title: 'Scalable Intelligence',
          description: 'Supports growing user demands and adapts to new requirements seamlessly',
        },
      ];
  return (
    <Stack sx={{ marginTop: '6.375rem', marginBottom: '6.375rem' }}>
            <Typography sx={{ fontSize: '2rem', fontWeight: '600', textAlign: 'center' }}>
                Why Choose Our AI-Powered Agent?
            </Typography>
            <Stack 
                direction={{ xs: 'column', sm: 'row' }} // Stack vertically on small screens, horizontally on larger
                spacing={2}
                sx={{ justifyContent: 'space-between', marginTop: '3.125rem' }}
            >
                {cardData.map((card, index) => (
                    <Box 
                        key={index} 
                        sx={{ 
                            width: isMdUp ? '29%' : '100%', // Adjust width based on screen size
                            marginBottom: '2rem' // Added margin for spacing on smaller screens
                        }}
                    >
                        <img 
                            src={card.img} 
                            style={{ 
                                width: '100%', 
                                height: '258px', 
                                marginBottom: '1.5rem' 
                            }} 
                            alt={card.title} 
                        />
                        <Typography sx={{ fontSize: '1.125rem', fontWeight: '600' }}>
                            {card.title}
                        </Typography>
                        <Typography sx={{ fontSize: '1rem', fontWeight: '400' }}>
                            {card.description}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Stack>
  );
};

export default ZepherCard;
