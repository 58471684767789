import { Box, styled } from "@mui/material";
import React, { useEffect } from "react";
import Banner from "./Banner";
import BlogCurosel from "./BlogCurosel";
import ServiceBanner from "./ServiceBanner";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Home = () => {
  const location = useLocation();
  console.log("home");
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, []);
  return (
    <Container>
      <Banner />
      <ServiceBanner />
      <BlogCurosel />
    </Container>
  );
};

export default Home;
const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 30,
}));
