import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import BannerBgImage from "../../../assets/image/intellivuzbackground.png";
import BannerDashboard from "../../../assets/image/intellivuzbannerDashboard.png";
import dashboard from "../../../assets/image/intellivuzDashboard.png";
import WrapperContainer from "../../../components/common/WrapperContainer";
import intell1 from "../../../assets/svg/intell1.svg";
import intell2 from "../../../assets/svg/intell2.svg";
import intell3 from "../../../assets/svg/ProductPerformance.svg";
import arrowicon from "../../../assets/svg/intellivuzarrow.svg";

const choose = [
  {
    title: "Understand Your Customers",
    subtitle:
      "Uncover patterns in customer behavior to tailor marketing and engagement",
  },
  {
    title: "Optimize Product Performance",
    subtitle:
      "Analyze product trends, stock levels, and profitability to boost sales",
  },
  {
    title: "Boost Sales Performance",
    subtitle:
      "rack sales metrics in real-time to identify growth opportunities and areas for improvement",
  },
];

const eCommerce = [
  {
    icon: intell1,
    title: "Customer Insights",
    subtitle:
      "Track user behavior, retention, and segmentation to improve targeting",
  },
  {
    icon: intell2,
    title: "Sales Analytics",
    subtitle:
      "Access sales reports, conversion rates, and revenue breakdowns to make data-backed decisions",
  },
  {
    icon: intell3,
    title: "Product Performance",
    subtitle:
      "Monitor product-specific data such as stock levels, margins, and trends to optimize your catalog",
  },
];

const platform = [
  {
    count: "1",
    icon: arrowicon,
    title: "Connect",
    subtitle:
      "Integrate seamlessly with your eCommerce platform and pull in real-time data",
  },
  {
    count: "2",
    icon: arrowicon,
    title: "Analyze",
    subtitle:
      "Access comprehensive analytics dashboards, designed for deep insights",
  },
  {
    count: "3",
    icon: "",
    title: "Act",
    subtitle:
      "Use insights to make informed decisions that optimize customer experience and drive growth",
  },
];

const Intellivuz = () => {
  const TopBanner = () => {
    return (
      <Box
      sx={{
        px: 3,
        py: 6,
        background: `url(${BannerBgImage})`,
        color: "#fff",
        display: "flex",
        flexDirection: isMdUp ? "row" : "column", // Change layout based on screen size
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        height: isMdUp ? "auto" : "auto", // Auto height on smaller screens
      }}
    >
     <Box width={isMdUp ? "50%" : "100%"}>
  <Typography variant="h1" gutterBottom color="#FFFFFF" sx={{ fontSize: isMdUp ? "3rem" : "2rem" }}>
    Data-Driven Insights for eCommerce Growth
  </Typography>
  <Typography variant="h5" sx={{ mb: 3 }} color="#FFFFFF" sx={{ fontSize: isMdUp ? "1.25rem" : "1rem" }}>
    Empowering you with actionable insights on customer behavior, sales,
    and product performance to optimize strategies and accelerate growth.
  </Typography>
  <Stack direction={isMdUp ? "row" : "column"} spacing={2} sx={{ mt: 2 }}> {/* Add spacing and adjust direction */}
    <Button
      variant="body1"
      sx={{ backgroundColor: "#FFF07C", color: "#252525" }}
    >
      Request a Demo
    </Button>
    <Button variant="outlined" color="#FFFFFF">
      See How It Works
    </Button>
  </Stack>
</Box>

      <Box width={isMdUp ? "auto" : "100%"}> {/* Full width on small screens */}
        <img
          src={BannerDashboard}
          alt="Dashboard Preview"
          width="100%"
          height={isMdUp ? 400 : "auto"} // Auto height on smaller screens
          style={{ maxHeight: isMdUp ? "400px" : "none", objectFit: "cover" }} // Maintain aspect ratio
        />
      </Box>
    </Box>
    );
  };

  const WhyChoose = () => {
    return (
      <Stack spacing={5} sx={{ py: 6, pt: "5rem", mx: 0 }}>
      <Typography variant="h2" gutterBottom textAlign={"center"}>
        Why Choose Our eCommerce BI Platform?
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems={"center"}
        sx={{ mx: 0, p: 0 }} // Add padding zero if needed
      >
        {choose.map((title, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: 3,
                backgroundColor: "#251F30",
                color: "#FFFFFF",
                height: "150px",
                width: "100%", // Ensure full width for the card
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                mx: 0 // Ensure no margin on the card
              }}
            >
              <CardContent>
                <Typography variant="h4" gutterBottom fontWeight={600}>
                  {title.title}
                </Typography>
                <Typography variant="h6" fontWeight={400}>
                  {title.subtitle}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
    
    
    
    );
  };

  const Insights = () => {
    return (
      <Box sx={{ py: 6, mt: '6rem', mb: '6rem' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom color="#0F0D1D">
            Comprehensive Insights for Every eCommerce Need
          </Typography>
          <Grid item xs={12} md={7} width={"100%"}>
            <Stack spacing={4}>
              {eCommerce.map((item, key) => (
                <Stack
                  key={key}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    width={50}
                    style={{ borderRadius: "8px" }}
                  />
                  <Stack spacing={0.5} sx={{ minWidth: "fit-content" }}>
                    <Typography variant="h5" color="#0F0D1D" fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="#585858">
                      {item.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={dashboard}
            alt="Dashboard Preview"
            height={isMdUp ? 400 : 250} // Adjust height based on screen size
            style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }} // Full width and maintain aspect ratio
          />
        </Grid>
      </Grid>
    </Box>
    );
  };

  const HowWorks = () => {
    return (
      <Box sx={{ py: 6, textAlign: "center" }}>
        <Typography variant="h2" gutterBottom>
          How Our Platform Works
        </Typography>
        <Grid container spacing={4} justifyContent="center" pt={"3rem"} >
          {platform.map((step, index) => (
            <Grid item xs={12} md={4} key={index} >
              <Card sx={{boxShadow:'none'}} >
                <CardContent sx={{ display: "flex", flexDirection: "row" ,justifyContent:'center',alignItems:'center',gap:'2rem'}}>
                  <Stack sx={{ display: "flex", flexDirection: "column" }}>
                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Typography variant="h2" color="#50209A" gutterBottom>
                        {step.count}
                      </Typography>
                      <Typography variant="h4" color="#0F0D1D" gutterBottom>
                        {step.title}
                      </Typography>
                    </Stack>
                    <Typography variant="h6" color="#585858" textAlign={"start"} marginLeft={'1.5rem'}>
                      {step.subtitle}
                    </Typography>
                  </Stack>
                  <Stack>
                    <img src={step.icon} alt="" width={30} />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack>
      <TopBanner />
      <WrapperContainer>
        <WhyChoose />
        <Insights />
        <HowWorks />
      </WrapperContainer>
    </Stack>
  );
};

export default Intellivuz;
