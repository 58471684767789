// BlogListPage.jsx
import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Paper, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const BlogListPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const storedBlogs = JSON.parse(localStorage.getItem("blogs")) || [];
    setBlogs(storedBlogs);
  }, []);

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Blog Posts
      </Typography>
      <Stack spacing={3}>
        {blogs.length > 0 ? (
          blogs.map((blog) => (
            <Paper key={blog.id} sx={{ p: 2 }}>
              <Typography variant="h6">
                {new Date(blog.timestamp).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" noWrap>
                {blog.content.replace(/<[^>]+>/g, "")}
              </Typography>
              <Link
                component={RouterLink}
                to={`/blogdetails/${blog.id}`}
                underline="hover"
                sx={{ mt: 1, display: "inline-block" }}
              >
                Read More
              </Link>
            </Paper>
          ))
        ) : (
          <Typography>No blogs available.</Typography>
        )}
      </Stack>
    </Box>
  );
};

export default BlogListPage;
