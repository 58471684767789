import Snowflack from "../assets/image/snowflack.png";
import AiEngineer from "../assets/image/ai-eng.png";
import FullStackDev from "../assets/image/fullstackdev.png";
import ETL from "../assets/image/image3.png";
export const posts = [
  {
    id: 1,
    name: "Madhan Srinivasan",
    date: "01 Oct 2024",
    title: "Creating a Chatbot with Langchain Using Local LLM.",
    role: "AI Engineer",
    content:
      "The only way to do great work is to love what you do. Success is not the key to happiness.In this article, we'll explore how to create a chatbot using the Langchain framework and a locally running Large Language Model (LLM). We'll delve into the essential concepts, tools, and code implementation to build a functional and engaging chatbot.",
    image: AiEngineer,
    avatarColor: "M",
    description: `<h1>Creating a Chatbot with Langchain Using Local LLM</h1>

<h2>Introduction</h2>
<p>In this article, we'll explore how to create a chatbot using the Langchain framework and a locally running Large Language Model (LLM). We'll delve into the essential concepts, tools, and code implementation to build a functional and engaging chatbot.</p>

<h2>Understanding the Components</h2>
<ul>
    <li><strong>Chatbot</strong>: A software application that simulates human-like conversation.</li>
    <li><strong>Large Language Model (LLM)</strong>: A type of generative AI that generates text completions based on human inputs.</li>
    <li><strong>Langchain</strong>: A framework that simplifies the development of AI applications, particularly those involving LLMs.</li>
    <li><strong>Ollama</strong>: A tool for running LLMs locally on your machine, even with limited computational resources.</li>
</ul>

<h2>Setting Up the Environment</h2>
<ol>
    <li><strong>Install Ollama</strong>: Download and install Ollama for your operating system.</li>
    <li><strong>Pull an LLM Model</strong>: Use Ollama to download a pre-trained LLM model, such as <code>llama3.1-8b</code>.</li>
    <li><strong>Install Langchain</strong>: Install Langchain and its required dependencies using pip:</li>
</ol>

<pre><code>pip install langchain langchain-community langchain-core</code></pre>

<p><strong>Use code with caution.</strong></p>

<h2>Creating the Chatbot</h2>

<h3>Import Necessary Modules</h3>
<pre><code>from langchain_core.prompts import PromptTemplate
from langchain_community.llms import Ollama
from langchain.chains import LLMChain</code></pre>
<p><strong>Use code with caution.</strong></p>

<h3>Initialize the LLM</h3>
<pre><code>llm = Ollama(model='llama3.1:8b')</code></pre>
<p><strong>Use code with caution.</strong></p>

<h3>Create a Prompt Template</h3>
<pre><code>prompt_template = PromptTemplate(
input_variables=[\"query\"],
template=\"Answer the following question: {query}\"
)</code></pre>
<p><strong>Use code with caution.</strong></p>

<h3>Create an LLMChain</h3>
<pre><code>chain = LLMChain(llm=llm, prompt=prompt_template)</code></pre>
<p><strong>Use code with caution.</strong></p>

<h3>Get User Input</h3>
<pre><code>query = input(\"Enter your query: \")</code></pre>
<p><strong>Use code with caution.</strong></p>

<h3>Generate a Response</h3>
<pre><code>response = chain.run(query)
print(response)</code></pre>
<p><strong>Use code with caution.</strong></p>

<h2>Enhancing the Chatbot</h2>
<ul>
    <li><strong>Memory</strong>: Implement a memory system to enable the chatbot to remember previous conversations and provide more context-aware responses.</li>
    <li><strong>Custom Prompts</strong>: Create custom prompts to guide the LLM's responses and tailor them to specific use cases.</li>
    <li><strong>Fine-tuning</strong>: Fine-tune the LLM on a dataset relevant to your chatbot's domain to improve its performance and accuracy.</li>
    <li><strong>Evaluation</strong>: Evaluate the chatbot's performance using metrics like accuracy, coherence, and relevance.</li>
</ul>

<h2>Conclusion</h2>
<p>By following these steps and incorporating additional features, you can create a chatbot that effectively interacts with users and provides valuable information. Remember to experiment with different LLM models, prompts, and techniques to find the best configuration for your specific needs.</p> `,
  },
  {
    id: 2,
    name: "Somnath",
    date: "12 Sep 2024",
    title: "Reusable Components: The Key to Efficient Front-End Development",
    role: "Software Engineer",
    content:
      "The best way to predict the future is to create it. The only place where success comes.>Reusable components are the backbone of scalable front-end applications. Whether you're building a small app or a large enterprise system, creating reusable, maintainable components is essential for improving efficiency, reducing redundancy, and enhancing code maintainability.",
    image: FullStackDev,
    avatarColor: "S",
    description: `<h1>Reusable Components: The Key to Efficient Front-End Development</h1>

        <style>
        pre {
            background-color: #f8f8f8;
            padding: 10px;
            border: 1px solid #ddd;
            overflow-x: auto;
            width:100%;
        }
    </style>

<h2>Introduction:</h2>
<p>Reusable components are the backbone of scalable front-end applications. Whether you're building a small app or a large enterprise system, creating reusable, maintainable components is essential for improving efficiency, reducing redundancy, and enhancing code maintainability.</p>

<h2>What are Reusable Components?</h2>
<p>A reusable component is a self-contained block of code that encapsulates a specific piece of functionality or UI element and can be used across various parts of an application. It adheres to the DRY (Don't Repeat Yourself) principle by allowing developers to write code once and use it multiple times.</p>
<p>In frameworks like React.js, reusable components help build dynamic, maintainable UIs. Think of buttons, modals, or form inputs — these are all examples of UI elements that can (and should) be reused in different contexts.</p>

<h2>Why Reusable Components Matter?</h2>
<ul>
  <li><strong>Efficiency:</strong> Reusable components allow you to build features once and use them across different parts of your application.</li></br>
  <li><strong>Consistency:</strong> By reusing the same component, you ensure consistency across your application's user interface.</li></br>
  <li><strong>Maintainability:</strong> Fixes and updates can be made in one place, instead of having to hunt for instances where similar code might be copied.</li></br>
  <li><strong>Scalability:</strong> Reusable components can be extended and adapted for larger applications as the project grows.</li></br>
</ul>

<h2>Key Principles for Building Reusable Components</h2>

<h3>1. Encapsulation and Separation of Concerns:</h3>
<p>Each component should be focused on a specific piece of functionality. Avoid combining too much logic into a single component.</p>

<h3>2. Props-Driven Configuration:</h3>
<p>Components should accept dynamic data via props. This makes them flexible and usable in different scenarios without hardcoding data.</p>
<pre><code>
const Button = ({ label, onClick }) => { 
  return <button onClick={onClick}>{label}</button>; 
}; 
</code></pre>

<h3>3. Modular and Composable Design:</h3>
<p>Design components that can be easily composed together. For example, build a Card component that can wrap around other elements like Header, Body, and Footer.</p>
<pre><code>
const Card = ({ children }) => { 
  return <div className="card">{children}</div>; 
}; 
</code></pre>

<h3>4. Single Responsibility:</h3>
<p>Each component should do one thing well. A button should only be responsible for rendering and handling a click event — not managing complex business logic.</p>

<h3>5. Avoid Over-Optimization:</h3>
<p>While reusability is crucial, avoid prematurely optimizing or making components too generic. Focus on actual use cases before making components overly flexible.</p>

<h2>Examples of Reusable Components</h2>

<h3>1. Button Component</h3>
<pre>
<code>
const Button = ({ label, type = 'button', onClick, disabled = false }) => { 
  return ( 
    <button type={type} onClick={onClick} disabled={disabled} className="btn"> 
      {label} 
    </button> 
  ); 
}; 
</code>
</pre>

<h3>2. Input Component</h3>
<pre><code>
const Input = ({ type = 'text', value, onChange, placeholder }) => { 
  return ( 
    <input 
      type={type} 
      value={value} 
      onChange={onChange} 
      placeholder={placeholder} 
      className="input" 
    /> 
  ); 
}; 
</code></pre>

<h3>3. Modal Component</h3>
<pre><code>
const Modal = ({ isVisible, onClose, children }) => { 
  if (!isVisible) return null; 
  return ( 
    <div className="modal"> 
      <div className="modal-content"> 
        <span className="close" onClick={onClose}>&times;</span> 
        {children} 
      </div> 
    </div> 
  ); 
}; 
</code></pre>

<h2>Best Practices for Reusable Components</h2>
<ul>
  <li><strong>Keep it Simple:</strong> Ensure your components are easy to understand. Overcomplicating components with too many props or internal logic can make them difficult to maintain.</li></br>
  <li><strong>Use Composition over Inheritance:</strong> Instead of creating large, complex components, compose smaller, simpler ones together.</li></br>
  <li><strong>Test Extensively:</strong> Since reusable components are often the backbone of your UI, they should be thoroughly tested to ensure they behave correctly in different contexts.</li></br>
  <li><strong>Prop Validation:</strong> Validate props using tools like TypeScript or PropTypes in React to ensure that your components are used correctly.</li></br>
  <li><strong>Styles and Theming:</strong> Ensure that your components can easily adapt to different styling needs, such as using CSS modules or theme providers.</li></br>
</ul>

<h2>Conclusion:</h2>
<p>Reusable components are a crucial aspect of modern web development, particularly when working with frameworks like React.js. By creating modular, flexible, and well-designed components, you can improve the maintainability and scalability of your codebase, making your development process more efficient and reducing the need for redundant code.</p>
 `,
  },
  {
    id: 3,
    name: "Sabareesan",
    date: "01 Sep 2024",
    title:
      "Handling Primary Key Constraints in Snowflake: Using Sequences for Unique Number Generation",
    role: "Data Engineer",
    content:
      "A sequence in Snowflake is a special object used to generate a new, unique number each time it is referenced.  In traditional databases, primary key constraints ensure that each value in a column is unique. However, Snowflake handles constraints like Primary Key a bit differently. In Snowflake, defining a column as a primary key does not inherently enforce uniqueness. This can lead to situations where duplicate values are allowed, which is contrary to expectations in many other database systems.   ",
    image: Snowflack,
    avatarColor: "S",
    description: `
    <h1>Handling Primary Key Constraints in Snowflake</h1>
    <style>
        pre {
            background-color: #f8f8f8;
            padding: 10px;
            border: 1px solid #ddd;
            overflow-x: auto;
        }
    </style>
</head>

    <h2>Handling Primary Key Constraints in Snowflake: Using Sequences for Unique Number Generation</h2>
    <p>
        In traditional databases, primary key constraints ensure that each value in a column is unique. However, Snowflake handles constraints like Primary Key a bit differently. In Snowflake, defining a column as a primary key does not inherently enforce uniqueness. This can lead to situations where duplicate values are allowed, which is contrary to expectations in many other database systems.
    </p>

    <h2>What is a Sequence in Snowflake?</h2>
    <p>
        A sequence in Snowflake is a special object used to generate a new, unique number each time it is referenced. Instead of relying on a primary key constraint to enforce uniqueness, you can use a sequence to ensure that each value in a particular column remains unique.
    </p>

    <h2>Step-by-Step Guide to Using Sequences in Snowflake</h2>

    <ol>
        <li>
            <h3>Creating a Table with a Primary Key (without enforcing uniqueness)</h3>
            <pre>
CREATE OR REPLACE TABLE T1 (
    id INT PRIMARY KEY,
    name VARCHAR NOT NULL
);</pre>
            <p>
                In this case, the id column is defined as the primary key, but Snowflake will not enforce uniqueness.
            </p>
        </li></br>

        <li>
            <h3>Creating a Sequence for Unique ID Generation</h3>
            <pre>
CREATE OR REPLACE SEQUENCE Seq_name;</pre>
            <p>
                By using this sequence, we can ensure that each row gets a unique id.
            </p>
        </li></br>

        <li>
            <h3>Inserting Data with Sequence-Generated IDs</h3>
            <pre>
INSERT INTO T1  
VALUES (Seq_name.NEXTVAL, 'A');</pre>
            <p>
                Here, Seq_name.NEXTVAL generates a new, unique id for the T1 table. The name column is manually populated with the value 'A'.
            </p>
        </li></br>

        <li>
            <h3>Starting the Sequence with a Specific Value</h3>
            <pre>
CREATE OR REPLACE SEQUENCE Seq_name 
START WITH 100;</pre>
            <p>
                Now, the first id generated will be 100, and subsequent values will be incremented from there.
            </p>
        </li></br>

        <li>
            <h3>Incrementing the Sequence by a Custom Value</h3>
            <pre>
CREATE OR REPLACE SEQUENCE Seq_name 
INCREMENT BY 10;</pre>
            <p>
                This will ensure that each new id is 10 more than the previous one.
            </p>
        </li></br>
    </ol>

    <h2>Final Thoughts</h2>
    <p>
        While Snowflake does not enforce uniqueness through primary key constraints, sequences provide a flexible and reliable way to generate unique numbers for your tables. By using sequences, you can easily control the starting point and increment value for your IDs, ensuring that each record has a unique identifier.
    </p>
    <p>
        By combining sequences with insert statements, you can easily maintain uniqueness in Snowflake, even in the absence of enforced primary key constraints.
    </p>
`,
  },
  {
    id: 4,
    name: "Aravindh",
    date: "01 Sep 2024",
    title: "How can we load the Delta load using talend and scheduling GoCD",
    role: "Data Engineer",
    content:
      "To load delta data(also known as incremental data) into a target system in Talend Open Studio, you need a well-defined process to extract only the changed data from the source and load it into the target system. Here’s a high-level guide to achieve this:",
    image: ETL,
    avatarColor: "A",
    description: `<h1>Delta Load Using Talend</h1>
<p>To load delta data (also known as incremental data) into a target system in Talend Open Studio, you need a well-defined process to extract only the changed data from the source and load it into the target system. Here’s a high-level guide to achieve this:</p>

<h2>1. Identify Delta Data (Changed Data)</h2>
<p>The first step is to identify what data has changed since the last ETL run. This can be done in several ways:</p>

<ul>
  <li><strong>Timestamp-based:</strong> 
  If your source has a column like Created date and Updated date, use it to filter records that have been modified since the last extraction.</li></br>

  <li><strong>Flag-based:</strong> 
  In some systems, a flag might be set when a row is modified, which can indicate whether the data has been inserted or updated.</li></br>

  <li><strong>Log Tables:</strong> 
  Some databases have logging tables that track changes (e.g., insert, update, or delete actions).</li></br>

  <li><strong>CDC (Change Data Capture):</strong> 
  Advanced databases and ETL tools can capture and replicate data changes in real-time using CDC mechanisms.</li></br>

</ul>

<h2>Automation Talend Job Using GoCD</h2>

<h3>1. Scheduling and Automation</h3>
<p>Automate the Talend Job by scheduling it to run at regular intervals (e.g., hourly, daily) using GoCD. Since you're using GoCD for scheduling, you can automate the Talend Open Studio by integrating your ETL jobs with GoCD. Here's how you can do that:</p>

<h4>Steps to Schedule Talend Jobs Using GoCD:</h4>
<ol>
  <li><strong>Build Your Talend Job:</strong></br>
    <ul>
      <li>Go to your Talend job, then click on <em>Build Job</em> and select an appropriate format such as a .sh or .bat file for Unix or Windows environments, respectively.</li>
    </ul>
  </li></br>


  <li><strong>Set Up GoCD Pipeline:</strong></br>
    <ul>
      <li>In GoCD, create a new pipeline for scheduling your ETL process.</li></br>
      <li>Add a material to your pipeline (e.g., Git).</li>
    </ul>
  </li></br>


  <li><strong>Create a Job in GoCD:</strong></br>
    <ul>
      <li>Inside the pipeline, create a job that runs your Talend job.</li></br>
      <li>Set up the task to run the exported Talend job (e.g., a .sh script for Linux or a .bat file for Windows).</li>
    </ul>
  </li></br>


  <li><strong>Schedule the Pipeline:</strong></br>
    <ul>
      <li>Use GoCD’s cron-like scheduling feature to set up when and how frequently the pipeline runs (e.g., hourly, daily, or weekly).</li></br>
      <li>In the pipeline configuration, you can specify the schedule using the format:
        <pre><code>0 0 * * *    # For daily runs at midnight, for example.</code></pre>
      </li>
    </ul>
  </li></br>


  <li><strong>Pass Parameters (Optional):</strong></br>
    <ul>
      <li>If your Talend job requires parameters (e.g., last run timestamp), you can pass these through GoCD's pipeline parameters.</li></br>
      <li>Use GoCD’s environment variables to pass dynamic values like the timestamp for delta loads into the Talend job.</li>
    </ul>
  </li></br>
  <li><strong>Monitor Job Execution:</strong></br>
    <ul>
      <li>GoCD will provide detailed logs and metrics of each run, helping you monitor the success or failure of your Talend job.</li></br>
      <li>You can also set up alerts or notifications in case of failures.</li>
    </ul>
  </li></br>


</ol>
`,
  },
];
