import { Box } from "@mui/material";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import AnnouncementBar from "./AnnouncementBar";
import ScrollToTop from "./ScrollTop";

const Layout = ({ children }) => (
  <>
    <AnnouncementBar />
    <Navbar />
    <ScrollToTop />
    <Box>{children}</Box>
    <Footer />
  </>
);

export default Layout;
