import React from "react";

const InstagramSvg = ({ width = "13", height = "13", color = "gray" }) => {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_220_1398)">
        <path
          d="M9.481 0H3.51898C1.57858 0 0 1.57858 0 3.51898V9.4811C0 11.4214 1.57858 13 3.51898 13H9.4811C11.4214 13 13 11.4214 13 9.4811V3.51898C13 1.57858 11.4214 0 9.481 0V0ZM12.2379 9.4811C12.2379 11.0012 11.0012 12.2379 9.481 12.2379H3.51898C1.99881 12.2379 0.762114 11.0012 0.762114 9.4811V3.51898C0.762114 1.99881 1.99881 0.762114 3.51898 0.762114H9.4811C11.0012 0.762114 12.2379 1.99881 12.2379 3.51898V9.4811Z"
          fill={color}
        />
        <path
          d="M6.49989 2.94543C4.53986 2.94543 2.94531 4.53998 2.94531 6.50002C2.94531 8.46005 4.53986 10.0546 6.49989 10.0546C8.45993 10.0546 10.0545 8.46005 10.0545 6.50002C10.0545 4.53998 8.45993 2.94543 6.49989 2.94543ZM6.49989 9.29248C4.96019 9.29248 3.70743 8.03982 3.70743 6.50002C3.70743 4.96032 4.96019 3.70755 6.49989 3.70755C8.03969 3.70755 9.29236 4.96032 9.29236 6.50002C9.29236 8.03982 8.03969 9.29248 6.49989 9.29248Z"
          fill={color}
        />
        <path
          d="M10.1397 1.68304C9.56047 1.68304 9.08936 2.15426 9.08936 2.73338C9.08936 3.3126 9.56047 3.78382 10.1397 3.78382C10.7189 3.78382 11.1901 3.3126 11.1901 2.73338C11.1901 2.15416 10.7189 1.68304 10.1397 1.68304ZM10.1397 3.0216C9.9808 3.0216 9.85147 2.89227 9.85147 2.73338C9.85147 2.57439 9.9808 2.44516 10.1397 2.44516C10.2987 2.44516 10.428 2.57439 10.428 2.73338C10.428 2.89227 10.2987 3.0216 10.1397 3.0216Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_220_1398">
          <rect width={width + ""} height={height + ""} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramSvg;
