import React from "react";
import BannerImage from "../../assets/image/service-bg.jpg";
import PersonImage from "../../assets/image/solving-img.png";
import GroupPersonImage from "../../assets/image/solving-img.jpg";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import WrapperContainer from "./WrapperContainer";

const AboutSection = () => {
  return (
    <Container>
      <WrapperContainer>
        <SubContainer>
          <ImageContainer>
            <ImagePosition sx={{ top: 0, left: 0 }}>
              <Image src={PersonImage} />
            </ImagePosition>
            <ImagePosition sx={{ bottom: 0, right: 0 }}>
              <Image src={GroupPersonImage} />
            </ImagePosition>
          </ImageContainer>
          <ContentContainer></ContentContainer>
        </SubContainer>
      </WrapperContainer>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  background: `url(${BannerImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "50px 0px",
}));
const SubContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
}));
const ImageContainer = styled(Box)(() => ({
  position: "relative",
  width: "50%",
  padding: "0px 10px",
  aspectRatio: 1.5,
}));
const ImagePosition = styled(Box)(() => ({
  position: "absolute",
}));
const Image = styled("img")(() => ({
  position: "absolute",
  display: "block",
  height: "100%",
  width: "100%",
}));
const ContentContainer = styled(Box)(() => ({
  width: "50%",
  padding: "0px 10px",
}));

export default AboutSection;
