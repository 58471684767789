import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const BlogDetailPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const blogs = JSON.parse(localStorage.getItem("blogs")) || [];
    const foundBlog = blogs.find((blog) => blog.id === id);
    setBlog(foundBlog || { title: "Blog Not Found", content: "", image: "" });
  }, [id]);

  return (
    <Box sx={{ padding: 4 }}>
      {blog ? (
        <>
          <Typography
            sx={{
              marginBottom: 2,
              color: "navy",
              fontSize: "3rem",
              fontWeight: "600",
            }}
          >
            {blog.title}
          </Typography>
          {/* Display the image if it exists */}
          {blog.image && (
            <img
              src={blog.image}
              alt="Blog"
              style={{
                width: "100%",
                height: "700px",
                borderRadius: "8px",
                marginBottom: "20px",
              }}
            />
          )}
          <Box
            sx={{ color: "black" }}
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </>
      ) : (
        <Typography variant="h5">Loading...</Typography>
      )}
    </Box>
  );
};

export default BlogDetailPage;
