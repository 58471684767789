import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import { ResponsiveStyles } from "./responsiveDimension";

const WrapperContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled(Box)(() => ({
  margin: "auto",
  ...ResponsiveStyles({
    width: { xs: "95%", md: "90%", lg: "85%" },
  }),
}));

export default WrapperContainer;
