import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/common/Layout';
import ProtectedRoute from '../components/protected/ProtectedRoute';


const AppRoutes = ({ routes }) => (
  <Routes>
    {routes.map((route, index) => {

      if (route.children) {
        return (
          <Route
            key={index}
            path={route.path}
            element={<Layout>{route.element}</Layout>}
          />
        );
      }

      const Element = route.isProtected ? <ProtectedRoute><route.element /></ProtectedRoute>: <route.element />;
      
      return (
        <Route
          key={index}
          path={route.path}
          element={<Layout>{Element}</Layout>}
        />
      );
    })}
  </Routes>
);

export default AppRoutes;
