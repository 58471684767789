import React from "react";
import CardLayoutB from "../../../components/common/CardLayoutB";
import image1 from "../../../assets/image/DE1.png";
import image2 from "../../../assets/image/DE2.png";
import image3 from "../../../assets/image/DE3.png";
import image4 from "../../../assets/image/DE4.png";
import image5 from "../../../assets/image/DE5.png";
import image6 from "../../../assets/image/DE6.png";
import image7 from "../../../assets/image/DE7.png";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/AIBanner.png";
import WrapperContainer from "../../../components/common/WrapperContainer";
import { Stack, Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
const DataEngineer = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const servicebanner = {
    title:
      "Redefine Your Business with Groundbreaking Data Engineering Solutions ",
    subtitle:
      " In today’s data-driven world, organizations need robust data infrastructure to transform raw data into actionable insights. At J7 Technology, we offer a comprehensive suite of Data Engineering services designed to harness the power of data for your business. Whether you’re looking to optimize data pipelines, enhance data quality, or leverage big data technologies, our team of experts is here to help you succeed",
      BannerImage:  BannerImage ,
  };
  const serviceData = [
    {
      title: "Data Pipeline Design & Optimization",
      description:
        " Ensure smooth and efficient data flow across your organization. We design, implement, and optimize data pipelines that handle massive amounts of data with ease, ensuring reliability and scalability",
      points: [
        "Real-time and batch processing ",
        "Automated ETL/ELT workflows ",
        "Fault-tolerant architecture ",
      ],
      imageUrl: image1,
    },
    {
      title: "Data Warehouse & Data Lake Implementation",
      description:
        " Store and manage your organization’s data efficiently. We build modern data warehouses and data lakes to centralize and streamline data access, providing a unified source of truth",
      points: [
        "Cloud and on-premise solutions ",
        "Data modeling and schema design",
        "Integration with BI and analytics tools ",
      ],
      imageUrl: image2,
    },
    {
      title: "Big Data Solutions",
      description:
        " Leverage cutting-edge technologies to process and analyze vast amounts of data. Our big data solutions enable businesses to derive valuable insights from complex data sets, ensuring you stay competitive",
      points: [
        "Hadoop, Spark, and NoSQL solutions ",
        "Distributed computing frameworks ",
        "Real-time data streaming and processing ",
      ],
      imageUrl: image3,
    },
    {
      // title: "",
      title: "Data Integration & Migration",
      description:
        " Seamlessly integrate data from disparate sources or migrate your data systems without disruption. We specialize in creating efficient integration strategies and smooth data migration paths",
      points: [
        "Legacy system modernization",
        "API-based and batch integrations",
        "Cloud data migration (AWS, GCP, Azure) ",
      ],
      imageUrl: image7,
    },
    {
      title: "Data Quality & Governance",
      description:
        " Ensure your data is accurate, consistent, and compliant. Our data quality and governance services help businesses maintain high standards of data integrity while adhering to industry regulations",
      points: [
        "Data profiling and validation ",
        "Metadata management ",
        "Regulatory compliance (GDPR, HIPAA) ",
      ],
      imageUrl: image4,
    },
    {
      title: "Data Security & Privacy",
      description:
        " Protect sensitive data with robust security frameworks. We help implement cutting-edge security measures to safeguard your data from threats and ensure privacy compliance",
      points: [
        "Encryption and anonymization ",
        "Data access control and monitoring",
        "Threat detection and prevention ",
      ],
      imageUrl: image5,
    },
    {
      title: "Cloud Data Engineering",
      description:
        " Optimize your cloud infrastructure to handle data at scale. We help organizations leverage the cloud for their data engineering needs, offering cost-efficient, scalable, and secure solutions",
      points: [
        "Cloud storage and database services ",
        "Data pipeline automation on the cloud",
        "Cloud-native architecture for data workflows ",
      ],
      imageUrl: image6,
    },
  ];
  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />
      <WrapperContainer>
        <Box sx={{ marginTop: "1rem" }}>
          {serviceData.map((service, key) => (
            <CardLayoutB
              key={key}
              service={service}
              isMdUp={isMdUp}
              isEven={key % 2 === 0}
            />
          ))}
        </Box>
      </WrapperContainer>
    </Stack>
  );
};

export default DataEngineer;
