import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const responsiveDimension = (xs, sm, md, lg, xl) => {
  return { xs, sm, md, lg, xl };
};
export const ScreenDimension = () => {
  const theme = useTheme();
  const XS = useMediaQuery(theme.breakpoints.up("xs"));
  const SM = useMediaQuery(theme.breakpoints.up("sm"));
  const MD = useMediaQuery(theme.breakpoints.up("md"));
  const LG = useMediaQuery(theme.breakpoints.up("lg"));
  const XL = useMediaQuery(theme.breakpoints.up("xl"));
  return { XS, SM, MD, LG, XL };
};

export const ResponsiveStyles = (styles) => {
  const breakpointsStyles = {};

  const theme = useTheme();
  Object.keys(styles).forEach((property) => {
    const values = styles[property];
    Object.keys(values).forEach((breakpoint) => {
      const style = {
        [property]: values[breakpoint],
      };

      breakpointsStyles[theme.breakpoints.up(breakpoint)] = {
        ...(breakpointsStyles[theme.breakpoints.up(breakpoint)] || {}),
        ...style,
      };
    });
  });
  return breakpointsStyles;
};
