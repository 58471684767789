import React from 'react'
import { Stack, Typography ,Box,Button,useMediaQuery} from '@mui/material'
import MovingIcon from "@mui/icons-material/Moving";
const ZepherBanner = () => {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <Stack
            sx={{
                width: '100%',
                height: 'auto', // Adjust the height as needed
                background: 'linear-gradient(90deg, rgba(1, 171, 199, 0.4) 0%, rgba(194, 240, 189, 0.7) 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '5rem',
                textAlign: 'center', // Center text for all screen sizes
            }}
        >
            <Typography sx={{ fontWeight: '600', fontSize: '2rem' }}>AI WEBSITE BUILDER</Typography>
            <Typography sx={{ fontSize: '3.75rem', fontWeight: '400', marginY: '1rem' }}>
                AI-Powered Agent for Intelligent, Automated Engagement
            </Typography>
            <Box sx={{ width: { xs: '90%', sm: '660px' }, marginTop: '1rem' }}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: '400', color: '#0F0D1D' }}>
                    Boost productivity, automate tasks, and deliver smarter solutions directly to your users with our AI-powered agent
                </Typography>
            </Box>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ margin: '4.625rem 0px' }}>
                <Button variant="contained" sx={{ backgroundColor: "#0F0D1D", color: 'white' }}>
                    Explore Solutions
                </Button>
                <Button variant="outlined" sx={{ color: '#0F0D1D', borderColor: "#0F0D1D" }}>
                    See How It Works <MovingIcon />
                </Button>
            </Stack>
        </Stack>
  )
}

export default ZepherBanner