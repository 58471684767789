import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  // IconButton,
  Stack,
  // useTheme,
  // useMediaQuery,
} from "@mui/material";
import {useNavigate} from 'react-router-dom'
// import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { RoutesPath } from "../../../routes/RoutesPath";

const BlogPost = ({
  key,
  id,
  name,
  date,
  title,
  content,
  image,
  avatarColor,
  description,role
}) => {

  const navigate = useNavigate()
  const handleClick = () => {
    navigate(RoutesPath.BLOGPOSTDETAIL, {
      state: {
        id,
        name,
        date,
        title,
        content,
        image,
        avatarColor,
        description,
        role
      },
    });
  };

  return (
    <Box sx={{ marginTop: "4rem" }}>
       <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Responsive layout
        gap: { xs: 2, md: 5 }, // Adjust gap for smaller screens
        marginBottom: "4rem",
        boxShadow: "none",
        alignItems: "center",
        justifyContent: "center",
        cursor:'pointer'
      }}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        sx={{
          width: { xs: "100%", md: 350 }, // Full width on small screens
          height: { xs: "100%", md: "200px" },
          borderRadius: "16px",
        }}
        image={image}
        alt={title}
      />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Avatar sx={{ bgcolor: avatarColor, mr: 1 }}>{name[0]}</Avatar>
            <Stack>
              <Typography variant="subtitle2">{name}</Typography>
              <Typography variant="body2" sx={{ color: "#919191" }}>
                {date}
              </Typography>
            </Stack>
          </Box>
          <Typography
            variant="h4"
            sx={{ color: "#0F0D1D", fontWeight: "600" }}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography variant="body1" color="#585858">
            {content}
          </Typography>
        </CardContent>
        {/* <Box sx={{ display: "flex", alignItems: "center", pl: 2, pb: 2 }}>
          <IconButton>
            <ThumbUpAltOutlinedIcon />
          </IconButton>
          <Typography variant="body2">{likes}</Typography>
          <IconButton sx={{ ml: 2 }}>
            <ChatBubbleOutlineOutlinedIcon />
          </IconButton>
          <Typography variant="body2">{comments}</Typography>
          <IconButton sx={{ ml: 2 }}>
            <VisibilityOutlinedIcon />
          </IconButton>
          <Typography variant="body2">{views}</Typography>
        </Box> */}
      </Box>
    </Card>
    </Box>
  );
};

export default BlogPost;