import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ContactAddress from "./ContactAddress";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/contactbanner.png";
import WrapperContainer from "../../../components/common/WrapperContainer";
import ContactUsMap from "./ContactUsMap";

const contactUsbanner = {
  title:
    "Contact Us",
  subtitle:
    "Have questions or looking to partner with us? We’re here to help! Whether you’re inquiring about our services, seeking technical support, or exploring new solutions, our team is ready to assist you.",
  BannerImage: BannerImage,
};

const ContactUs = () => {


  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack>
      {!isMdUp && (
           <Banner
           title={contactUsbanner.title}
           subtitle={contactUsbanner.subtitle}
           BannerImage={contactUsbanner.BannerImage}
           isbuttonvisible={false}
         />
      )}
       <Box position={isMdUp ? "relative" : ""} pb={8}>
        {/* Banner Section */}
       {isMdUp &&  <Banner
          title={contactUsbanner.title}
          subtitle={contactUsbanner.subtitle}
          BannerImage={contactUsbanner.BannerImage}
          isbuttonvisible={false}
        />
       }
        
        {/* Contact Address Popout Section */}
        <Box
          position= "absolute" 
          top={isMdUp ? "70%" : "60%"} // Adjust to control how much it overlaps
          left="50%"
          sx={{
            transform: "translateX(-50%)",
            width: { xs: "90%", sm: "80%", md: "70%" },
            zIndex: 10,
          }}
        >
          <ContactAddress />
        </Box>
      </Box>
      <Box sx={{marginTop:isMdUp ? '20rem' : "60rem"}}>
      <WrapperContainer>
      {/* <ContactAddress /> */}
      <ContactUsMap />
      </WrapperContainer>
      </Box>
    </Stack>
  );
};

export default ContactUs;
