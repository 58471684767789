import { Stack, Typography, Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import image1 from "../../assets/image/tab1img.png";
import image2 from "../../assets/image/tab2img.png";
import image3 from "../../assets/image/tab3img.png";
import {useMediaQuery,useTheme} from "@mui/material";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CarzhenSectionC = () => {
  
   const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const points1 = [
    "Easy appointment scheduling, online access to records, and direct communication with healthcare providers",
    "Secure messaging for quick consultations with doctors",
    "Option to set medication reminders and track health metrics",
    "Access to personalized health education materials and resources",
    "Integration with wearable devices for seamless health data syncing",
    "Virtual consultations with specialists from the comfort of home",
  ];
  const points2 = [
    'Centralized patient records, telehealth tools, and coordination with other care providers',
    'Patient engagement platforms with interactive features for remote monitoring',
    'Healthcare data analytics tools for personalized treatment plans',
    'Secure messaging systems for efficient communication between healthcare professionals',
    'Integration of wearable devices data into patient records for comprehensive health tracking',
    'AI-powered decision support systems to assist healthcare providers in clinical decision-making'
  ]
  const points3 = [
    'Hospital administration, patient record management, and inter-departmental communication tools.',
    'Medical billing systems and insurance claim processing.',
    'Appointment scheduling and reminders for patients and staff.',
    'Electronic health record (EHR) software for comprehensive patient information.',
    'Inventory management for medical supplies and equipment.',
    'Quality assurance and compliance tracking tools for healthcare institutions.'
  ]
  return (
    <Stack>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "2rem",
          textAlign: "center",
          marginBottom: "5.875rem",
        }}
      >
        Solutions for Every Part of the Healthcare Journey
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ background: "transparent" }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#018B95",
              },
            }} // Set Tabs background to transparent
          >
            <Tab
              label="For Patients"
              sx={{
                background: "transparent",
                color: value === 0 ? "blue" : "black", // Active font color
                "&.Mui-selected": {
                  color: "#018B95", // Active font color
                },
              }}
            />{" "}
            {/* Tab background transparent */}
            <Tab
              label="For Providers"
              sx={{
                background: "transparent",
                color: value === 1 ? "blue" : "black", // Active font color
                "&.Mui-selected": {
                  color: "#018B95", // Active font color
                },
              }}
            />
            <Tab
              label="For Hospitals"
              sx={{
                background: "transparent",
                color: value === 2 ? "blue" : "black", // Active font color
                "&.Mui-selected": {
                  color: "#018B95", // Active font color
                },
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"} // Stack vertically on small screens
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box
          sx={{
            width: isMdUp ? "40%" : "100%", // Full width on small screens
            marginTop: "2.813rem",
          }}
        >
          <img
            src={image1}
            alt="Description"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Box
          sx={{
            width: isMdUp ? "60%" : "100%", // Full width on small screens
            paddingLeft: isMdUp ? "4rem" : "0", // No padding on small screens
            marginTop: "2.813rem",
          }}
        >
          <Stack spacing={4} sx={{ paddingY: '2rem' }}>
            {points1.map((point, index) => (
              <Stack key={index} direction="row" alignItems="center">
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#018B95",
                    marginRight: "0.5rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "1rem",
                    color: "#585858",
                  }}
                >
                  {point}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </CustomTabPanel>


        <CustomTabPanel value={value} index={1}>
        <Box
            display="flex"
            flexDirection={isMdUp ? "row" : "column"} // Stack vertically on small screens
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box  sx={{
            width: isMdUp ? "40%" : "100%", // Full width on small screens
            marginTop: "2.813rem",
          }}>
              <img
                src={image2}
                alt="Description"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
            <Box
               sx={{
                width: isMdUp ? "60%" : "100%", // Full width on small screens
                paddingLeft: isMdUp ? "4rem" : "0", // No padding on small screens
                marginTop: "2.813rem",
              }}
            >
              <Stack spacing={4}>
                {points2.map((point, index) => (
                  <Stack key={index} direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#018B95",
                        marginRight: "0.5rem",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "1rem",
                        color: "#585858",
                      }}
                    >
                      {point}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
        <Box
           display="flex"
           flexDirection={isMdUp ? "row" : "column"} // Stack vertically on small screens
           justifyContent="space-between"
           alignItems="flex-start"
          >
            <Box  sx={{
            width: isMdUp ? "40%" : "100%", // Full width on small screens
            marginTop: "2.813rem",
          }}>
              <img
                src={image3}
                alt="Description"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
            <Box
             sx={{
              width: isMdUp ? "60%" : "100%", // Full width on small screens
              paddingLeft: isMdUp ? "4rem" : "0", // No padding on small screens
              marginTop: "2.813rem",
            }}
            >
              <Stack spacing={4}>
                {points3.map((point, index) => (
                  <Stack key={index} direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#018B95",
                        marginRight: "0.5rem",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "1rem",
                        color: "#585858",
                      }}
                    >
                      {point}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
    </Stack>
  );
};

export default CarzhenSectionC;
