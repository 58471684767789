import { AIService, DataEngineer } from "../pages";
import BlogPostDetail from "../pages/blog/blogpostdetail";
import Blog from "../pages/blog/post";
import DataAnalytics from "../pages/service/dataanalytics";
import DataEntryService from "../pages/service/dataentry";
import Mobile from "../pages/service/mobileappservice";
import UIUX from "../pages/service/uiuxservice";
import WebsiteApp from "../pages/service/websitedevservice";
// import UIUXService from "../pages";
import { lazyLoad } from "../utils/lazyLoad";
import { RoutesPath } from "./RoutesPath";
import AdminPage from "../pages/admin";
import BlogPostDetails from "../pages/blog/blogpage";
import BlogListPage from "../pages/blog/bloglist";
import ContactUs from "../pages/contactus/contact";
import DigitalMarketingService from "../pages/service/digitalmarketing";
import Carzhen from "../pages/carzhen";
import Zepher from "../pages/zepher";
import Intellivuz from "../pages/product/intellivuz";
// import Service from "../pages/Services";
const Home = lazyLoad(() => import("../pages/home")); // Correct dynamic import
const About = lazyLoad(() => import("../pages/about"));
const Trackgle = lazyLoad(() => import("../pages/Trackgle"));
const CareersDetails = lazyLoad(() => import("../pages/careersdetails"));
const Career = lazyLoad(() => import("../pages/careers"));
const personaldetailsform = lazyLoad(() =>
  import("../pages/personaldetailsform")
);

const routes = [
  {
    path: RoutesPath.HOME,
    element: Home,
  },
  {
    path: RoutesPath.ABOUT,
    element: About,
  },
  {
    path: RoutesPath.AISERVICE,
    element: AIService,
  },
  {
    path: RoutesPath.DATAENTRY,
    element: DataEntryService,
  },
  {
    path: RoutesPath.MOBILEAPPSERVICE,
    element: Mobile,
  },
  {
    path: RoutesPath.WEBSITEAPPSERVICE,
    element: WebsiteApp,
  },
  {
    path: RoutesPath.DATAENGINEER,
    element: DataEngineer,
  },
  {
    path: RoutesPath.DATAANALYTICS,
    element: DataAnalytics,
  },
  {
    path: RoutesPath.UIUXSERVICE,
    element: UIUX,
  },
  {
    path: RoutesPath.BLOGPOSTDETAIL,
    element: BlogPostDetail,
  },
  {
    path: RoutesPath.BLOGPOST,
    element: Blog,
  },
  {
    path: RoutesPath.BLOGADMIN,
    element: AdminPage,
  },
  {
    path: RoutesPath.BLOGDETAIL,
    element: BlogPostDetails,
  },
  {
    path: RoutesPath.BLOGLIST,
    element: BlogListPage,
  },
  {
    path: RoutesPath.ContactUs,
    element: ContactUs,
  },
  {
    path: RoutesPath.DIGITALMARKETING,
    element: DigitalMarketingService,
  },
  {
    path: RoutesPath.TRACKGLE,
    element: Trackgle,
  },
  {
    path: RoutesPath.CAREERSDETAILS,
    element: CareersDetails,
  },
  {
    path: RoutesPath.CARZHEN,
    element: Carzhen,
  },
  {
    path: RoutesPath.ZEPHER,
    element: Zepher,
  },
  {
    path: RoutesPath.INTELLIVUZ,
    element: Intellivuz,
  },
  {
    path: RoutesPath.CAREERS,
    element: Career,
  },
  {
    path: RoutesPath.PERSONALFORM,
    element: personaldetailsform,
  },
];

export default routes;
