import React from "react";
import Banner from "../../../components/common/Banner";
import WrapperContainer from "../../../components/common/WrapperContainer";
import { Stack } from "@mui/material";
import BannerImage from "../../../assets/image/websitebanner.png";
import icon1 from "../../../assets/svg/image5.svg";
import icon2 from "../../../assets/svg/image6.svg";
import icon3 from "../../../assets/svg/image7.svg";
import icon4 from "../../../assets/svg/image8.svg";
import icon5 from "../../../assets/svg/image9.svg";
import icon6 from "../../../assets/svg/image10.svg";
import icon7 from "../../../assets/svg/image11.svg";
import icon8 from "../../../assets/svg/image12.svg";
import CardLayoutC from "../../../components/servicelayout/CardLayoutC.js";

const servicebanner = {
  title:
    "Transform Your Vision: Comprehensive Website Development Services to Propel Your Business Forward",
  subtitle:
    "In the modern digital landscape, having a mobile app is no longer a luxury but a necessity  for businesses looking to engage with their customers and optimize their operations. At J7  Technology, we specialize in creating feature-rich, user-friendly mobile applications that  meet your business needs. Here’s a comprehensive list of the mobile app development  services we offer",
  BannerImage: BannerImage,
};

const MobileService = [
  {
    title: "Custom Website Development",
    description:
      "Every business is unique, and so should be its website. Our custom website development services focus on creating tailored solutions that align perfectly with your business goals.",
    points: [
      "Tailored Design and Development",
      "Responsive Web Design",
      "Content Management System (CMS) Integration",
    ],
    icon: icon3,
  },
  {
    title: "E-commerce Website Development ",
    description:
      "Empower your online sales with our robust e-commerce website development services. We create secure, scalable platforms that enhance customer engagement and boost conversions. ",
    points: [
      "Shopping Cart Integration",
      "Payment Gateway Integration",
      "Inventory Management Systems",
      "Customer Account Management",
    ],
    icon: icon4,
  },
  {
    title: "Web Application Development",
    description:
      "Elevate your online presence with advanced web applications that enhance user engagement and streamline processes. Our web application services focus on delivering dynamic, interactive solutions. ",
    points: [
      "Custom Web Applications",
      "API Development and Integration",
      "Progressive Web Apps (PWAs)",
      "Real-Time Data Processing",
    ],
    icon: icon1,
  },
  {
    title: "Content Management System (CMS) Development ",
    description:
      "Empower your team to manage content effortlessly with our CMS development services. We specialize in creating intuitive systems that simplify content updates and management. ",
    points: [
      "Custom CMS Solutions",
      "Training and Support",
      "Integration with Existing Systems",
      "Multi-Language Support",
    ],
    icon: icon2,
  },
  {
    title: " Website Maintenance and Support",
    description:
      "Ensure your website remains functional, secure, and up-to-date with our comprehensive website maintenance and support services. ",
    points: [
      "Regular Updates and Backups",
      "Performance Monitoring",
      "Technical Support",
      "Security Audits",
    ],
    icon: icon7,
  },
  {
    title: "SEO-Optimized Website Development",
    description:
      "Boost your online visibility with our SEO-focused website development services. We create websites that are not only beautiful but also optimized for search engines. ",
    points: [
      "On-Page SEO Optimization",
      "Mobile Optimization",
      "Performance Optimization",
      "Schema Markup Implementation",
    ],
    icon: icon8,
  },
  {
    title: "Mobile Optimization ",
    description:
      "With a significant portion of web traffic coming from mobile devices, our mobile optimization services ensure your website delivers a stellar experience on smartphones and tablets. ",
    points: [
      "Responsive Design",
      "Touch-Friendly Interfaces",
      "Mobile Page Speed Optimization",
    ],
    icon: icon5,
  },
  {
    title: " API Integration and Development ",
    description:
      "Expand the capabilities of your website with our API integration and development services. Connect your site with external systems and applications for enhanced functionality. ",
    points: [
      "Third-Party API Integration",
      "Custom API Development",
      "Data Synchronization",
    ],
    icon: icon6,
  },
  {
    title: "Website Analytics and Reporting ",
    description:
      "Gain valuable insights into your website’s performance with our analytics and reporting services. We help you track key metrics and make data-driven decisions. ",
    points: [
      "Google Analytics Setup",
      "Custom Reporting Dashboards",
      "Heatmaps and User Tracking",
    ],
    icon: icon1,
  },
];

const WebsiteApp = () => {
  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />
      <WrapperContainer>
        <Stack direction="row" sx={{ flexWrap: "wrap" }}>
          {MobileService.map((service, key) => (
            <CardLayoutC
              title={service.title}
              description={service.description}
              points={service.points}
              icon={service.icon}
              key={key}
            />
          ))}
        </Stack>
      </WrapperContainer>
    </Stack>
  );
};

export default WebsiteApp;
