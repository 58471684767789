import { CssBaseline } from "@mui/material";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import darkTheme from "./darkTheme";
import lightTheme from "./lightTheme";

const ThemeProvider = ({ children }) => {
  const [mode] = useState(
    localStorage.getItem("mode") === "dark" ? "dark" : "light"
  );

  // Toggle between light and dark themes
  const theme = useMemo(() => {
    return mode === "light" ? lightTheme : darkTheme;
  }, [mode]);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
