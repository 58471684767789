import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import Banner from "../../components/common/Banner";
import AboutSection from "../../components/common/AboutSection";

const About = () => {
  return (
    <Container>
      <Banner />
      <AboutSection />
    </Container>
  );
};

const Container = styled(Box)(() => ({}));

export default About;
