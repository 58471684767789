import React from "react";
import WrapperContainer from "../../../components/common/WrapperContainer";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/DABanner.png";
import image1 from "../../../assets/image/DA1.png";
import image2 from "../../../assets/image/DA2.png";
import image3 from "../../../assets/image/DA3.png";
import image4 from "../../../assets/image/DA4.png";
import image5 from "../../../assets/image/DA5.png";
import image6 from "../../../assets/image/DA6.jpg";
// import image6 from "../../../assets/image/DA6.png";

import CardLayoutB from "../../../components/common/CardLayoutB";
const DataAnalytics = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const servicebanner = {
    title:
      "Unlocking Insights: Comprehensive Data Analytics Services to Drive Business Growth ",
    subtitle: `
In today's data-driven world, businesses must leverage data analytics to make informed decisions and gain a competitive edge. At J7 Technology, we offer a wide range of data analytics services designed to help organizations transform raw data into actionable insights. Our team of experts utilizes advanced tools and techniques to unlock the full potential of your data. Here’s a comprehensive list of the data analytics services we provide: 
`,
BannerImage: BannerImage ,
  };
  const serviceData = [
    {
      title: " Descriptive Analytics ",
      description:
        "  Descriptive analytics helps businesses understand historical data and identify trends. We analyze past performance to provide insights into what has happened, allowing you to make informed decisions for the future. ",
      points: [
        `Data Aggregation and Reporting `,
        `Visualization of Historical Trends `,
        "KPI Tracking and Dashboard Creation ",
        "Performance Analysis and Benchmarking ",
        "Business Intelligence (BI) Reporting ",
      ],
      imageUrl: image1,
    },
    {
      title: "Predictive Analytics ",
      description:
        "  Predictive analytics uses statistical algorithms and machine learning techniques to forecast future outcomes. We analyze historical data to predict trends, customer behavior, and potential risks, helping you make proactive decisions. ",
      points: [
        "Predictive Modeling and Forecasting ",
        "Customer Behavior Analysis ",
        "Risk Assessment and Management ",
        "Time Series Analysis ",
        "Demand Forecasting ",
      ],
      imageUrl: image2,
    },
    {
      title: "Real-Time Analytics ",
      description:
        "In today’s fast-paced environment, real-time analytics allows businesses to make immediate decisions based on current data. We implement real-time analytics solutions to help you stay agile and responsive to market changes. ",
      points: [
        "Stream Processing and Real-Time Data Integration ",
        "Dashboard Development for Live Data Monitoring ",
        "Alert Systems for Immediate Insights ",
        "Performance Tracking and Optimization ",
        "Real-Time Decision Making Tools ",
      ],
      imageUrl: image3,
    },
    {
      // title: "",
      title: " Data Visualization ",
      description:
        "  Effective data visualization is crucial for communicating insights clearly and compellingly. Our team creates interactive dashboards and visual reports that make complex data easily understandable. ",
      points: [
        "Custom Dashboard Development ",

        "Interactive Visualizations (Tableau, Power BI, etc.)",

        "Infographics and Data Storytelling",

        "Geographic Information Systems (GIS) Mapping",

        "User-Centric Reporting Solutions",
      ],
      imageUrl: image4,
    },
    {
      title: " Customer Analytics ",
      description:
        "  Understanding customer behavior is key to driving engagement and loyalty. Our customer analytics services provide insights into preferences, purchasing patterns, and segmentation to enhance your marketing strategies. ",
      points: [
        "Customer Segmentation and Profiling",

        "Lifetime Value (CLV) Analysis",

        "Churn Prediction and Retention Strategies",

        "Sentiment Analysis and Customer Feedback",

        "Targeted Marketing Campaign Development",
      ],
      imageUrl: image5,
    },
    {
      title: " Operational Analytics ",
      description:
        "  We help businesses optimize their operations through operational analytics. By analyzing internal processes and performance metrics, we identify areas for improvement and efficiency gains.  ",
      points: [
        "Process Optimization and Efficiency Analysis",

        "Supply Chain Analytics",

        "Resource Utilization and Productivity Analysis",

        "Performance Benchmarking",

        "Operational Dashboards and Reporting",
      ],
      imageUrl: image6,
    },
  ];

  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />
      <WrapperContainer>
        <Box sx={{ marginTop: "1rem" }}>
          {serviceData.map((service, key) => (
            <CardLayoutB
              key={key}
              service={service}
              isMdUp={isMdUp}
              isEven={key % 2 === 0}
            />
          ))}
        </Box>
      </WrapperContainer>
    </Stack>
  );
};

export default DataAnalytics;
