import { Stack } from "@mui/material";
import React from "react";
import icon1 from "../../../assets/svg/image5.svg";
import icon2 from "../../../assets/svg/image6.svg";
import icon3 from "../../../assets/svg/image7.svg";
import Banner from "../../../components/common/Banner";
import icon4 from "../../../assets/svg/image8.svg";
import icon5 from "../../../assets/svg/image9.svg";
import icon6 from "../../../assets/svg/image10.svg";
import icon7 from "../../../assets/svg/image11.svg";
import icon8 from "../../../assets/svg/image12.svg";
import BannerImage from "../../../assets/image/mobilebanner.png";
import WrapperContainer from "../../../components/common/WrapperContainer";
import CardLayoutC from "../../../components/servicelayout/CardLayoutC.js";

const servicebanner = {
  title:
    "Empower Your Business with Innovative  Mobile App Development Services",
  subtitle:
    "In the modern digital landscape, having a mobile app is no longer a luxury but a necessity  for businesses looking to engage with their customers and optimize their operations. At J7  Technology, we specialize in creating feature-rich, user-friendly mobile applications that  meet your business needs. Here’s a comprehensive list of the mobile app development  services we offer",
    BannerImage: BannerImage ,
};

const MobileService = [
  {
    title: "Enterprise Mobile Solutions",
    description:
      "Our enterprise mobile apps empower businesses to improve efficiency, automate  processes, and connect teams. We create scalable and secure enterprise apps that  enhance internal operations and employee productivity",
    points: [
      "Enterprise Resource Planning (ERP) Mobile Apps",
      "Employee Collaboration and Communication Apps",
      "Mobile CRM Systems",
      "Workflow Automation and Task Management Apps",
      "Secure Data Integration and Analytics",
    ],
    icon: icon3,
  },
  {
    title: "Custom Mobile App Development",
    description:
      "We build fully customized mobile applications tailored to your business requirements.  Whether you need an app for iOS, Android, or cross-platform, our team creates solutions  that deliver seamless experiences to your users",
    points: [
      "Enterprise Resource Planning (ERP) Mobile Apps",
      "Native iOS and Android App Development",
      "Cross-Platform App Development (React Native)",
      "Custom Features and Functionality Development",
      "Mobile UI/UX Design",
      "App Store Submission and Support",
    ],
    icon: icon4,
  },
  {
    title: "App Integration with Emerging Technologies",
    description:
      "We help you stay ahead of the curve by integrating your mobile app with the latest in  emerging technologies. Whether it’s AI, IoT, or blockchain, we ensure your app leverages  cutting-edge tools to offer unique user experiences",
    points: [
      "AI-Powered Chatbots and Voice Assistants",
      "Internet of Things (IoT) Mobile App Integration",
      "Blockchain for Secure Transactions",
      "Cloud-Based Mobile Solutions",
    ],
    icon: icon1,
  },
  {
    title: "Mobile App Testing and QA",
    description:
      "We understand the importance of delivering flawless mobile apps to end-users. Our  mobile app testing and quality assurance services ensure that your app is bug-free, fast,  and responsive on all devices",
    points: [
      "Functional Testing for iOS and Android Apps",
      "Performance and Load Testing",
      "Usability and Accessibility Testing",
      "Cross-Device Compatibility Testing",
      "Automated and Manual Testing Services",
    ],
    icon: icon2,
  },
  {
    title: "Mobile App UI/UX Design",
    description:
      "A well-designed app makes all the difference in delivering a superior user experience. Our  design team focuses on creating intuitive, visually appealing mobile interfaces that keep  users engaged and coming back",
    points: [
      "User-Centered Mobile UI/UX Design",
      "Wireframing and Prototyping",
      "Interactive Mockups",
      "Responsive Design for Different Devices and Screen Sizes",
      "User Testing and Feedback Analysis",
    ],
    icon: icon7,
  },
  {
    title: "eCommerce Mobile App Development",
    description:
      "With our expertise in eCommerce app development, we help businesses bring their  products and services to mobile users. Our solutions are scalable, secure, and feature  advanced integrations to streamline your online sales",
    points: [
      "Mobile eCommerce App Design and Development",
      "Secure Payment Gateway Integration",
      "Multi-Vendor Marketplace Solutions",
      "Inventory Management and Order Tracking",
      "Personalized User Experiences and Product Recommendations",
    ],
    icon: icon8,
  },
  {
    title: "Mobile App Marketing and ASO",
    description:
      "Developing an app is just the first step. We help your app gain visibility and attract  downloads with our comprehensive mobile marketing and App Store Optimization (ASO)  services",
    points: [
      "App Store Optimization (ASO) for iOS and Android",
      "In-App Advertising Strategies",
      "Social Media Marketing Campaigns",
      "User Acquisition and Retention Strategies",
      "App Performance Analytics and Insights",
    ],
    icon: icon5,
  },
  {
    title: "App Maintenance and Support",
    description:
      "Our work doesn’t stop at launch. We provide ongoing maintenance and support to ensure  your app remains up-to-date with the latest technologies, runs smoothly, and continues to  meet your business goals",
    points: [
      "Regular App Updates and Feature Enhancements",
      "Bug Fixing and Performance Optimization",
      "Server and Database Management",
      "Security Patches and Upgrades",
      "24/7 Technical Support",
    ],
    icon: icon6,
  },
];

const Mobile = () => {
  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />

      <WrapperContainer>
        <Stack direction="row" sx={{ flexWrap: "wrap" }}>
          {MobileService.map((service, key) => (
            <CardLayoutC
              title={service.title}
              description={service.description}
              points={service.points}
              icon={service.icon}
              key={key}
            />
          ))}
        </Stack>
      </WrapperContainer>
    </Stack>
  );
};

export default Mobile;
