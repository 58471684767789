import styled from "@emotion/styled";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
// import BannerImage from "../../assets/image/banner.jpg";
import CustomButton from "./CustomButton";
import MovingIcon from "@mui/icons-material/Moving";
import BannerRoundImage from "../../assets/image/rounding.png";
import BannerFadeImage from "../../assets/image/fade-right-banner.png";
import { useNavigate } from "react-router-dom";
import { ResponsiveStyles } from "./responsiveDimension";

const Banner = ({
  title = "About Us",
  subtitle = "",
  BannerImage,
  isbuttonvisible = true,
  buttontext = "Request a Demo",
  navigation = "/contactus",
  handleClick = () => null,
  isClick = false,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleNavigation = () => {
    if (isClick) {
      handleClick();
    } else {
      navigate(navigation);
    }
  };

  return (
    <Container sx={{ padding: isMdUp ? "" : "1rem" }} BannerImage={BannerImage}>
      <TitleContainer>
        <Typography
          variant={isMdUp ? "h2" : "h5"}
          fontWeight={600}
          color="white"
        >
          {title}
        </Typography>
        <Typography
          variant={isMdUp ? "h6" : "body2"}
          fontWeight={500}
          color="white"
        >
          {subtitle}
        </Typography>
      </TitleContainer>
      <BannerSubImage sx={{ right: 0 }}>
        <Image src={BannerRoundImage} />
      </BannerSubImage>
      <BannerSubImage sx={{ left: 0 }}>
        <Image src={BannerFadeImage} />
      </BannerSubImage>
      {isbuttonvisible && (
        <Box>
          <CustomButton
            onClick={handleNavigation}
            sx={{ backgroundColor: "#606FFF" }}
          >
            {buttontext}
            <MovingIcon />
          </CustomButton>
        </Box>
      )}
    </Container>
  );
};

const Container = styled(Box)(({ theme, BannerImage }) => ({
  background: `url(${BannerImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...ResponsiveStyles({
    aspectRatio: { xs: 1, md: 3.2 },
  }),
  backgroundPosition: "center",
  position: "relative",
  flexDirection: "column",
  gap: "1rem",
  zIndex: 0,
  // [theme.breakpoints.down("md")]: {
  //   height: "500px",
  // },

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(270.07deg, #002B98 0.07%, #00060C 99.95%)",
    zIndex: -1,
    opacity: 0.75,
  },
}));

const BannerSubImage = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  pointerEvents: "none",
  height: "100%",
}));
const Image = styled("img")(() => ({
  height: "100%",
  width: "100%",
  display: "block",
}));
const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "70%",
  ...ResponsiveStyles({
    width: { xs: "95%", md: "70%" },
  }),
  textAlign: "center",
  gap: "1rem",

  // [theme.breakpoints.down("md")]: {
  //  width:'100%'
  // },
}));

export default Banner;
