import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import tickicon from "../../assets/svg/Tick Icon.svg";

const CardLayoutB = ({ service, isMdUp, key, isEven }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMdUp ? (isEven ? "row" : "row-reverse") : "column",
        alignItems: "center",
        justifyContent: "space-between",
        columnGap: "135px",
        width: "100%",
        paddingBottom:"5rem"
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: isMdUp ? "50%" : "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#3344E8",
            position: "absolute",
            top: "-1%",
            left: isEven ? "0" : "auto",
            right: isEven ? "auto" : "0",
            width: isMdUp ? "30%" : "30%", // Adjust width to be more responsive
            height: isMdUp ? "100%" : "100%", // Adjust height to be more responsive
            // borderRadius: "8px",
            zIndex: 1,
          }}
        ></Box>
        <Box
          sx={{
            position: "relative",
            zIndex: 2, // Keep the image above the background box
            margin: "1rem",
            left: isEven ? "2%":"-2%",
            right: isEven ? "0":"10%",
          }}
        >
          <img
            src={service.imageUrl}
            width={"100%"}
            alt="service_image"
            // style={{ borderRadius: "8px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: isMdUp ? "50%" : "100%",
          marginTop: isMdUp ? "" : "1rem",
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ fontWeight: "600" }}>
          {service.title}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          gutterBottom
          sx={{ color: "#585858" }}
        >
          {service.description}
        </Typography>
        <List dense>
          {service.points.map((point, idx) => (
            <ListItem key={idx} sx={{paddingLeft: "0px"}}>
              <ListItemIcon>
                <img src={tickicon} alt="tick" />
              </ListItemIcon>
              <ListItemText
                primary={point}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#585858",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CardLayoutB;
