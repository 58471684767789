import { Box, Stack, Typography,Button } from '@mui/material'
import React from 'react'
import carzhen1 from '../../assets/image/carzhen1.png'
import { Row } from '@react-email/components'
import WrapperContainer from '../../components/common/WrapperContainer'
import CustomButton from '../../components/common/CustomButton'
import MovingIcon from "@mui/icons-material/Moving";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
const CarzhenSectionA = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
     
    <Stack 
    direction={{ xs: 'column', md: 'row' }} 
    spacing={2} 
    marginTop='5rem'
>
    <Box 
        sx={{
            width: { xs: '100%', md: '60%' },
            padding: '4rem 2rem',  
        }}
    >
        <Typography sx={{ fontSize: '3.125rem', fontWeight: '700' }}>
            Seamless Healthcare Solutions for Connected, Compassionate Care
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', fontWeight: '400', color: '#5F5F60', marginTop: '2rem' }}>
            Empowering patients, providers, and hospitals with the tools to
        </Typography>
        <Typography sx={{ fontSize: '1.25rem', fontWeight: '400', color: '#5F5F60' }}>
            deliver efficient, coordinated, and patient-centered healthcare
        </Typography>
        <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2} 
            sx={{ margin: '4.625rem 0px' }}
        >
            <Button variant="contained" sx={{ backgroundColor: "#018B95" }}>
                Explore Solutions
            </Button>
            <Button 
                variant="outlined" 
                sx={{ color: '#018B95', borderColor: "#018B95" }}
            >
                Watch our video <MovingIcon />
            </Button>
        </Stack>
    </Box>
    <Box 
        sx={{
            width: { xs: '100%', md: '40%' },
            padding: '4.625rem 2rem',  
        }}
    >
        <img src={carzhen1} alt="Healthcare Solutions" width="100%" />
    </Box>
</Stack>
    
  )
}

export default CarzhenSectionA