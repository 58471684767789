import React from "react";

const FacebookSvg = ({ height = "12", width = "7", color = "gray" }) => {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99826 1.9925H6.09376V0.0845C5.90476 0.0585 5.25476 0 4.49776 0C2.91826 0 1.83626 0.9935 1.83626 2.8195V4.5H0.0932617V6.633H1.83626V12H3.97326V6.6335H5.64576L5.91126 4.5005H3.97276V3.031C3.97326 2.4145 4.13926 1.9925 4.99826 1.9925Z"
        fill={color}
      />
    </svg>
  );
};

export default FacebookSvg;
