import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import React, { Fragment } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { colors } from "../../utils/Colors/Colors";

const SideDrawer = ({ open = false, handleClose = () => null }) => {
  const list = [
    { name: "Home", navigate: "/" },
    {
      name: "Services",
      navigate: "/",
      menu: [
        {
          title: "Data Engineering",
          navigation: "/dataengineer",
        },
        {
          title: "AI Engineering",
          navigation: "/aiservice",
        },
        {
          title: "App Development",
          navigation: "/mobileappservice",
        },
        {
          title: "UI/UX Design",
          navigation: "/uiuxservice",
        },
        {
          title: "Website Development",
          navigation: "/websiteappservice",
        },
        {
          title: "Digital Marketing",
          navigation: null,
        },
        {
          title: "Data Analytics",
          navigation: "/dataanalytics",
        },
        {
          title: "Data Entry",
          navigation: "/dataentry",
        },
      ],
    },
    {
      name: "Products",
      navigate: "/",
      menu: [
        {
          title: "CareZhen",
          navigation: "/carzhen",
        },
        {
          title: "Zhepher",
          navigation: "/zepher",
        },
        {
          title: "Trackgle",
          navigation: "/trackgle",
        },
        {
          title: "Intellivuz",
          navigation: "/intellivuz",
        },
      ],
    },
    { name: "Blogs", navigate: "/blogpost", menu: [] },
    { name: "Contact Us", navigate: "/contactus", menu: [] },
    { name: "Career", navigate: "/careers", menu: [] },
  ];

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <List>
        {list.map((value, index) => (
          <Fragment key={index}>
            {value.menu && value.menu.length > 0 ? (
              <AccordionEdit key={index} disableGutters elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                >
                  <ListItemText primary={value.name} />
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding>
                    {value.menu.map((subItem, subIndex) => (
                      <ListItem
                        button
                        component={Link}
                        to={subItem.navigation}
                        key={subIndex}
                        onClick={handleClose}
                      >
                        <ListItemText
                          primary={subItem.title}
                          sx={{ color: colors.black }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </AccordionEdit>
            ) : (
              <ListItem
                button
                component={Link}
                to={value.navigate}
                key={index}
                onClick={handleClose}
              >
                <ListItemText
                  primary={value.name}
                  sx={{ color: colors.black }}
                />
              </ListItem>
            )}
            <Divider />
          </Fragment>
        ))}
      </List>
    </Drawer>
  );
};

const AccordionEdit = styled(Accordion)(() => ({
  "&::before": {
    display: "none",
  },
}));

export default SideDrawer;
