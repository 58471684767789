import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import tickicon from "../../assets/svg/Tick Icon.svg";

const CardLayoutC = ({ title, description, points, icon, key }) => {
  return (
    <Stack
      key={key}
      sx={{
        width: { xs: "100%", md: "50%" },
        padding: 2,
        marginTop: "4rem",
      }}
    >
      <Stack sx={{ alignSelf: "flex-start", marginBottom: "30px" }}>
        <img src={icon} alt={`${title} icon`} height={100} />
      </Stack>
      <Stack sx={{ width: { xs: "100%", md: "90%" } }}>
        <Typography
          variant="h4"
          sx={{ color: "#0F0D1D", marginBottom: "24px" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#585858", marginBottom: "40px" }}
        >
          {description}
        </Typography>
      </Stack>
      <List dense>
        {points?.map((point, idx) => (
          <ListItem key={idx} sx={{ paddingLeft: "0px" }}>
            <ListItemIcon>
              <img src={tickicon} alt="tick" />
            </ListItemIcon>
            <ListItemText
              primary={point}
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#585858",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default CardLayoutC;
