import styled from "@emotion/styled";
import { Box, Link, Typography } from "@mui/material";
import React from "react";
import WrapperContainer from "./WrapperContainer";
import { ResponsiveStyles } from "./responsiveDimension";
import FaceBookSvg from "../../assets/svg/FacebookSvg";
import InstagramSvg from "../../assets/svg/InstagramSvg";
import LinkedinSvg from "../../assets/svg/LinkedinSvg";
import YoutubeSvg from "../../assets/svg/youtube";
import Twittersvg from "../../assets/svg/TwitterSvg";

const Footer = () => {
  const Heading = ({ title }) => {
    return (
      <Typography fontSize={16} fontWeight={700} color="white">
        {title}
      </Typography>
    );
  };
  const Text = ({ title, subTitle }) => {
    return (
      <TextName>
        {subTitle && <span style={{ fontWeight: 700 }}>{subTitle} : </span>}
        {title}
      </TextName>
    );
  };
  const serviceslist = [
    { name: "AI Engineering" },
    { name: "App Development" },
    { name: "UI/UX Design" },
    { name: "Data Analytics" },
    { name: "Website Development" },
  ];

  const linkslist = [
    { name: "Home" },
    { name: "Services" },
    { name: "About Us" },
    { name: "Contact" },
    { name: "Careers" },
  ];
  const contactDetails = [
    {
      type: "Address",
      value: "11/54J, Albert Complex, PT Nagar, ALM, Tenkasi",
    },
    {
      type: "Email",
      value: "hello@jseven.in",
    },
    {
      type: "Phone",
      value: "+91 9489032912",
    },
    {
      type: "Fax",
      value: "+91 9123561257",
    },
  ];

  const socialmediaIcons = [
    { name: "Facebook", icon: FaceBookSvg, url: null },
    { name: "Instagram", icon: InstagramSvg, url: null },
    { name: "Twitter", icon: Twittersvg, url: null },
    {
      name: "LinkedIn",
      icon: LinkedinSvg,
      url: "https://in.linkedin.com/company/j7technology",
    },
    { name: "YouTube", icon: YoutubeSvg, url: null },
  ];

  return (
    <Container>
      <WrapperContainer>
        <SubContainer>
          <ContentContainer>
            <Heading title={"Follow Us"} />
            <Text
              title={
                "Even if we had a clear definition of company culture, there is no recipe book to follow for producing the kind of company culture you want."
              }
            />
            <IconsContainer>
              {socialmediaIcons.map((Value, index) => (
                <LinkButton key={index} href={Value?.url} target="_blank">
                  <SvgIcon>
                    <Value.icon width={20} height={20} color={"black"} />
                  </SvgIcon>
                </LinkButton>
              ))}
            </IconsContainer>
          </ContentContainer>
          <ContentContainer>
            <Heading title={"Services"} />
            {serviceslist.map((service, index) => (
              <Text key={index} title={service.name} />
            ))}
          </ContentContainer>
          <ContentContainer>
            <Heading title={"Links"} />
            {linkslist.map((link, index) => (
              <Text key={index} title={link.name} />
            ))}
          </ContentContainer>
          <ContentContainer>
            <Heading title={"Contact us"} />
            <LinkButton
              href="https://www.google.com/maps/search/?api=1&query=J7%20Technology%20Solutions%20Private%20Limited%2C%2011%2F54%20J%2C%20Puratchi%20Nagar%2C%20Alangulam%2C%20Tenkasi%20District%2C%20Tamil%20Nadu%20-627851"
              target="_blank"
            >
              <Text
                subTitle={"Address"}
                title={
                  "11/54 J,  Puratchi Nagar, Alangulam, Tenkasi District, Tamil Nadu -627851"
                }
              />
            </LinkButton>

            <LinkButton href="mailto:hello@jseven.in">
              <Text subTitle={"Email"} title={"hello@jseven.in"} />
            </LinkButton>
            <LinkButton href="tel:+91-9489032912">
              <Text subTitle={"Phone"} title={"+91-9489032912"} />
            </LinkButton>
            <Text subTitle={"Fax"} title={"+91-9123561257"} />
          </ContentContainer>
        </SubContainer>
      </WrapperContainer>
      <AllRights>
        © 2024 Copyright by J7 Technology Solutions Pvt Ltd. All rights
        reserved.
      </AllRights>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  backgroundColor: "black",
  padding: "50px 0px",
}));
const SubContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
}));
const AllRights = styled(Typography)(() => ({
  textAlign: "center",
  width: "100%",
  color: "white",
  borderTop: "1px solid white",
  paddingTop: 20,
  marginTop: 20,
}));
const ContentContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  gap: 10,
  padding: 10,
  ...ResponsiveStyles({
    width: {
      xs: "100%",
      md: "50%",
      lg: "25%",
    },
  }),
}));

const IconsContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
}));
const TextName = styled(Box)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: "white",
  cursor: "pointer",
}));

const SvgIcon = styled(Box)(() => ({
  backgroundColor: "white",
  width: 40,
  height: 40,
  borderRadius: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all .2s linear",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
    transition: "all .2s linear",
  },
}));

const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

export default Footer;
