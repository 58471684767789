import React from "react";

function Switch() {
  return (
    <svg
      width="40"
      height="16"
      viewBox="0 0 40 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="25.6667"
        height="15"
        rx="7.5"
        stroke="#384BFF"
      ></rect>
      <rect
        x="13.3334"
        width="26.6667"
        height="16"
        rx="8"
        fill="#384BFF"
      ></rect>
    </svg>
  );
}

export default Switch;
