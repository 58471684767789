import { Box, Link, styled, Typography } from "@mui/material";
import React from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WrapperContainer from "./WrapperContainer";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import InstagramSvg from "../../assets/svg/InstagramSvg";
import FacebookSvg from "../../assets/svg/FacebookSvg";
import TwitterSvg from "../../assets/svg/TwitterSvg";
import LinkedinSvg from "../../assets/svg/LinkedinSvg";
import YoutubeSvg from "../../assets/svg/youtube";
import { colors } from "../../utils/Colors/Colors";
import { ScreenDimension } from "./responsiveDimension";

function AnnouncementBar() {
  const { MD } = ScreenDimension();
  return (
    <>
      {MD && (
        <MainContainer>
          <WrapperContainer>
            <Container>
              <Box display={"flex"} alignItems={"center"} columnGap={"8px"}>
                <EmailOutlinedIcon sx={{ color: "text.teritary" }} />
                <LinkButton href="mailto:hello@jseven.in">
                  <Typography variant="h6" sx={{ color: "text.teritary" }}>
                    hello@jseven.in
                  </Typography>
                </LinkButton>
                <PhoneInTalkOutlinedIcon sx={{ color: "text.teritary" }} />
                <LinkButton href="tel:+91-9489032912">
                  <Typography variant="h6" sx={{ color: "text.teritary" }}>
                    +91-9489032912
                  </Typography>
                </LinkButton>
              </Box>
              <Box display={"flex"} alignItems={"center"} columnGap={"16px"}>
                <Typography
                  variant="h6"
                  sx={{ color: "background.paper", fontWeight: 550 }}
                >
                  Follow Us:
                </Typography>
                <FacebookSvg color={colors.white} height={15} width={15} />
                <TwitterSvg color={colors.white} height={15} width={15} />
                <LinkButton
                  href={"https://in.linkedin.com/company/j7technology"}
                  target="_blank"
                >
                  <LinkedinSvg color={colors.white} height={15} width={15} />
                </LinkButton>

                <InstagramSvg color={colors.white} height={15} width={15} />
                <YoutubeSvg color={colors.white} height={20} width={20} />
              </Box>
            </Container>
          </WrapperContainer>
        </MainContainer>
      )}
    </>
  );
}

export default AnnouncementBar;
const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#0f0d1d",
  padding: "10px 0",
}));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));
