import React from "react";

function WebDevelopment({ color = "#384BFF", width = 22, height = 22 }) {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.128C19 5.296 19 3.381 17.828 2.19C16.656 0.999 14.771 1 11 1H9C5.229 1 3.343 1 2.172 2.19C1.001 3.38 1 5.296 1 9.128C1 12.96 1 14.875 2.172 16.066C2.642 16.543 3.226 16.829 4 17"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 16.5C21 14.625 21 13.688 20.523 13.03C20.3688 12.8178 20.1822 12.6312 19.97 12.477C19.312 12 18.375 12 16.5 12H11.5C9.625 12 8.688 12 8.03 12.477C7.8178 12.6312 7.63118 12.8178 7.477 13.03C7 13.689 7 14.626 7 16.5C7 18.374 7 19.312 7.477 19.97C7.63148 20.1823 7.81844 20.3689 8.031 20.523C8.688 21 9.625 21 11.5 21H16.5C18.375 21 19.312 21 19.97 20.523C20.1822 20.3688 20.3688 20.1822 20.523 19.97C21 19.312 21 18.375 21 16.5Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 15L16.42 15.793C16.807 16.126 17 16.293 17 16.5C17 16.707 16.807 16.874 16.42 17.207L15.5 18M12.5 15L11.58 15.793C11.193 16.126 11 16.293 11 16.5C11 16.707 11.193 16.874 11.58 17.207L12.5 18M1.5 5H18.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default WebDevelopment;
