import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#D400F9",
    },
    secondary: {
      main: "#ff9100",
    },
    background: {
      // default: "#f5f5f5",
      default:'#ffffff',
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#555555",
      teritary: "#ffffffcc",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontSize: "1rem",
      textTransform: "none", // Disable uppercase
      fontWeight: 500,
      backgroundColor: "GrayText",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
});

export default lightTheme;
