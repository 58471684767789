import React from 'react'
import CarzhenSectionA from './SectionA'
import WrapperContainer from '../../components/common/WrapperContainer'
import CarzhenSectionB from './SectionB'
import CarzhenSectionC from './SectionC'
import CarazhenSectionD from './SectionD'
import CarazhenSectionE from './SectionE'
import CarezhenFooter from './SectionF'
const Carzhen = () => {
  return (
 <>
    <WrapperContainer>
        <CarzhenSectionA/>
        <CarzhenSectionB/>
        <CarzhenSectionC/>
        <CarazhenSectionD/>
        <CarazhenSectionE/>
    </WrapperContainer>
    <CarezhenFooter/>

 </>
  )
}

export default Carzhen