import React from "react";
import CardLayoutB from "../../../components/common/CardLayoutB";
import image1 from "../../../assets/image/DMimage1.png";
import image2 from "../../../assets/image/DMimage2.png";
import image3 from "../../../assets/image/DMimage3.png";
import image4 from "../../../assets/image/DMimage4.png";
import image5 from "../../../assets/image/DMimage5.png";
import image6 from "../../../assets/image/DMimage7.png";
import image7 from "../../../assets/image/DMimage8.png";
import image8 from "../../../assets/image/DMimage9.png";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/DMBanner.png";
import WrapperContainer from "../../../components/common/WrapperContainer";
import { Stack, Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";

const DigitalMarketingService = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const servicebanner = {
    title:
      "Ignite Your Brand: Cutting-Edge Digital Marketing Services to Propel Business Growth ",
    subtitle:
      " In today's hyper-connected world, an effective digital marketing strategy is essential for businesses aiming to capture attention, engage audiences, and convert leads. At J7 technology, we deliver a suite of innovative digital marketing services designed to elevate your brand's presence and maximize your ROI. Here’s a detailed overview of our offerings, infused with the latest technologies and strategies to help you dominate your market. ",
      BannerImage: BannerImage,
  };

  const serviceData = [
    {
      title: "Search Engine Optimization (SEO)",
      description:
        "Unlock the full potential of your online presence with our comprehensive SEO services. By leveraging data-driven strategies, we ensure that your website ranks at the pinnacle of search engine results. ",
      points: [
        "In-Depth Keyword Research ",
        "On-Page Optimization ",
        "Technical SEO ",
        "Local SEO Strategies:",
      ],
      imageUrl: image1,
    },
    {
      title: "Pay-Per-Click (PPC) Advertising",
      description:
        "Supercharge your traffic and conversions with strategically crafted PPC campaigns. Our targeted approach ensures you reach the right audience at the right time. ",
      points: [
        "Google Ads Management",
        "Retargeting Strategies",
        "Ad Performance Analytics",
      ],
      imageUrl: image2,
    },
    {
      title: "Social Media Marketing",
      description:
        "Harness the power of social media to build brand loyalty and engage your community. Our dynamic strategies turn followers into advocates. ",
      points: [
        "Social Media Strategy Development",
        "Content Creation and Curation",
        "Advanced Analytics",
      ],
      imageUrl: image3,
    },
    {
      title: "Advanced Analytics",
      description:
        "Nurture leads and build lasting relationships with personalized email campaigns. Our targeted approach ensures your message reaches the right people. ",
      points: [
        "Segmentation and Targeting",
        "Automated Campaigns",
        "A/B Testing",
      ],
      imageUrl: image4,
    },
    {
      title: " Influencer Marketing",
      description:
        "Leverage the credibility of influencers to enhance your brand visibility and reach. Our strategic partnerships create authentic connections with your audience. ",
      points: [
        "Influencer Identification",
        "Campaign Management",
        "Performance Metrics",
      ],
      imageUrl: image5,
    },
    {
      title: "Tracking and Reporting:",
      description:
        "Transform raw data into actionable insights with our advanced web analytics services. Understand user behavior and enhance your digital strategy. ",
      points: [
        "Google Analytics Setup",
        "Conversion Tracking",
        "Custom Reporting Dashboards",
      ],
      imageUrl: image6,
    },
    {
      title: "Custom Reporting Dashboards",
      description:
        "Maximize your website’s potential with our CRO strategies. We analyze user behavior and implement enhancements that drive conversions. ",
      points: [
        "User Experience (UX) Analysis",
        "A/B Testing",
        "Heatmaps and Session Recordings",
      ],
      imageUrl: image7,
    },
    {
      title: " Mobile Marketing",
      description:
        "Engage your audience on the go with our mobile marketing solutions. Optimize your strategy to reach users on their devices. ",
      points: ["SMS Marketing", "Mobile App Marketing"],
      imageUrl: image8,
    },
  ];
  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />
      <WrapperContainer>
        <Box sx={{ marginTop: "2rem", mb: "2rem" }}>
          {serviceData.map((service, key) => (
            <CardLayoutB
              key={key}
              service={service}
              isMdUp={isMdUp}
              isEven={key % 2 === 0}
            />
          ))}
        </Box>
      </WrapperContainer>
    </Stack>
  );
};

export default DigitalMarketingService;
