import React from "react";
import { Stack } from "@mui/material";
import BlogPost from "./BlogPost";
import blog1 from "../../../assets/image/blog1.png";
import blog2 from "../../../assets/image/blog2.png";
import blog3 from "../../../assets/image/blog3.png";
import blog4 from "../../../assets/image/blog4.png";
import avataricon from "../../../assets/svg/Ellipse1.svg";
import WrapperContainer from "../../../components/common/WrapperContainer";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/blogbanner.png";
// import Snowflack from "../../../assets/image/snowflack.png";
// import AiEngineer from "../../../assets/image/ai-eng.png";
// import FullStackDev from "../../../assets/image/fullstackdev.png";
// import ETL from '../../../assets/image/image3.png'
import { posts } from "../../../utils/BlogData";

const Blog = () => {
  const servicebanner = {
    title: "Blog",
    BannerImage: BannerImage,
  };

  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
        isbuttonvisible={false}
      />
      <WrapperContainer>
        {posts.map((item, index) => (
          <BlogPost
            key={index}
            id={item.id}
            name={item.name}
            date={item.date}
            title={item.title}
            content={item.content}
            image={item.image}
            avatarColor={item.avatarColor}
            likes={item.likes}
            comments={item.comments}
            views={item.views}
            description={item.description}
            role={item.role}
          />
        ))}
      </WrapperContainer>
    </Stack>
  );
};

export default Blog;
