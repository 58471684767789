import styled from "@emotion/styled";
import { Box, Stack, Typography, Button, Grid } from "@mui/material";
import React from "react";
import BannerBgImage from "../../assets/image/trackgle-banner-img.png";
import desktop from "../../assets/image/trackgle-desktop.png";
import mobile from "../../assets/image/trackgle-mobile.png";
import banericon1 from "../../assets/svg/UserGroupSvg.svg";
import banericon2 from "../../assets/svg/CollabortionSvg.svg";
import banericon3 from "../../assets/svg/WaterfallSvg.svg";
import banericon4 from "../../assets/svg/InterFaceSvg.svg";
import banericon5 from "../../assets/svg/AnalyticsUpSvg.svg";
import dot from "../../assets/svg/Ellipse 4.svg";
import trackgletool from "../../assets/image/trackgle-tool-img.png";
import WrapperContainer from "../../components/common/WrapperContainer";
import sneakPeekImage from "../../assets/image/trackgle-desktop-Admin2.png";
import feature2in1 from "../../assets/image/feature2in1.png";
import MovingIcon from "@mui/icons-material/Moving";

const chooseTool = [
  {
    icon: banericon1,
    title: "Boost Team Productivity",
    subtitle:
      "Track time and manage sprints in one place, so your team stays aligned and focused.",
  },
  {
    icon: banericon2,
    title: "Seamless Collaboration",
    subtitle:
      "Manage tasks, timelines, and priorities to ensure everyone is on the same page",
  },
  {
    icon: banericon3,
    title: "Data-Driven Insights",
    subtitle:
      "Real-time analytics give you insights to make informed decisions on project timelines and resources",
  },
  {
    icon: banericon4,
    title: "User-Friendly Interface",
    subtitle:
      "Intuitive design that makes it easy for users to navigate and utilize all features",
  },
  {
    icon: banericon5,
    title: "Advanced Reporting",
    subtitle:
      "Generate detailed reports on project performance and team productivity",
  },
];

const feature = [
  {
    icon: dot,
    title: "Time Tracking",
    subtitle: "Track time spent on tasks and monitor project budgets",
  },
  {
    icon: dot,
    title: "Sprint Planning",
    subtitle: "Plan, prioritize, and assign tasks with a sprint calendar",
  },
  {
    icon: dot,
    title: "Task Dependencies",
    subtitle: " Create task relationships to manage workflow effectively",
  },
  {
    icon: dot,
    title: "Real-Time Analytics",
    subtitle:
      "Insights into team performance, project timelines, and resource allocation",
  },
  {
    icon: dot,
    title: "Customizable Dashboard",
    subtitle: "Customize your dashboard to show key metrics",
  },
  {
    icon: dot,
    title: "Collaboration Tools",
    subtitle: "Chat, comment, and share files within tasks",
  },
  {
    icon: dot,
    title: "Kanban Board",
    subtitle: " Visualize workflow stages and progress at a glance",
  },
  {
    icon: dot,
    title: "File Attachments",
    subtitle: "Attach important files and documents to tasks",
  },
  {
    icon: dot,
    title: "Mobile Accessibility",
    subtitle: "Access and manage tasks on the go",
  },
];

const Trackgle = () => {
  
  const TopBannerWithMVAndDV = () => {
    return (
      <Container>
        <SubContainer >
          <ContentContainer >
            <Typography variant="h4" sx={{ color: "#fff", fontWeight: "bold" }}>
              Optimize Your Team’s Productivity with Smart{" "}
              <span style={{ backgroundColor: "#384BFF" ,padding:'0.1rem'}}>Project Management</span>
            </Typography>
            <Typography variant="body1" sx={{ color: "#fff", mt: 2 }}>
              Integrated time tracking and project planning ensures every
              project is delivered successfully, on time, and on budget!
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
              <Button variant="contained" sx={{ backgroundColor: "#4D9EF8" }}>
                Sign Up Free
              </Button>
              <Button
                variant="outlined"
                sx={{ color: "#fff", borderColor: "#fff" }}
              >
                Request a demo <MovingIcon />
              </Button>
            </Stack>
          </ContentContainer>
        </SubContainer>
        <BannerImageContainer>
          <img src={desktop} alt="desktop view" className="desktop-image" />
          <img src={mobile} alt="mobile view" className="mobile-image" />
        </BannerImageContainer>
      </Container>
    );
  };

  const WhyChoose = () => {
    return (
      <Stack spacing={4}>
        <Typography variant="h3" align="center" color="#0E0C1A">
          Why Choose Our Tool
        </Typography>

        <Grid container spacing={4} alignItems="center">
          {/* Image on the Left Side */}
          <Grid item xs={12} md={5}>
            <img
              src={trackgletool}
              alt="Trackgle Tool"
              width="100%"
              style={{ borderRadius: "8px" }}
            />
          </Grid>

          {/* Benefits List on the Right Side */}
          <Grid item xs={12} md={7} >
            <Stack spacing={4 }>
              {chooseTool.map((item, key) => (
                <Stack
                  key={key}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    width={50}
                    style={{ borderRadius: "8px" }}
                  />
                  <Stack spacing={0.5}>
                    <Typography variant="h5" color="#0F0D1D" fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="#585858">
                      {item.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const EffectiveProjectManagement = () => {
    return (
      <Stack spacing={4} mt={10}>
        <Typography variant="h3" align="center">
        Features Built for <span style={{color:'#384BFF'}}>Efficient Project Management</span>
        </Typography>

        <Grid container spacing={4} alignItems="center">
          {/* Image on the Left Side */}
          <Grid item xs={12} md={5}>
            <img
              src={feature2in1}
              alt="Trackgle Tool"
              width="100%"
              style={{ borderRadius: "8px" }}
            />
          </Grid>

          {/* Benefits List on the Right Side */}
          <Grid item xs={12} md={7}>
            <Stack spacing={2}>
              {feature.map((item, key) => (
                <Stack
                  key={key}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    width={10}
                    style={{ borderRadius: "8px" }}
                  />
                  <Stack spacing={1} display={"flex"} flexDirection={"row"} columnGap={1} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h5" color="#0F0D1D" fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="#585858">
                      {item.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const SneakPeek = () => {
    return(
      <Stack spacing={4} mt={10} mb={10}>
        <Stack textAlign={"center"} spacing={2}>
        <Typography variant="h4">Get a <span style={{color:'#384BFF'}}>Sneak Peek</span></Typography>
        <Typography>Explore how our intuitive design helps you manage tasks, track time, and plan sprints all from a single platform</Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <img src={sneakPeekImage} alt="" width={"80%"}/>
        </Stack>
      </Stack>
    )
  }
  return (
    <>
      <TopBannerWithMVAndDV />
      <WrapperContainer>
        <WhyChoose />
        <EffectiveProjectManagement />
        <SneakPeek />
      </WrapperContainer>
    </>
  );
};
export default Trackgle;


const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  marginBottom:'3rem',
  [theme.breakpoints.down("md")]: {
    aspectRatio: "1.5",
  },
  [theme.breakpoints.up("md")]: {
    aspectRatio: "2.5",
  },
}));

const SubContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "80%",
  background: `linear-gradient(0.65deg, #1D246D 0.51%, rgba(29, 38, 130, 0) 133.02%), url(${BannerBgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(4),
  position: "relative",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: "500px",
  color: "#fff",
  textAlign: "left",
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    width: "80%",
  },
}));

const BannerImageContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: "60%",
  transform: "translateX(-50%)",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  paddingBottom: theme.spacing(4),
  zIndex: 1,

  "& .desktop-image": {
    width: "60%",
    maxWidth: "650px",
    height: "430px",
  },
  "& .mobile-image": {
    width: "30%",
    // height:'400px',
    maxWidth: "200px",
    position: "absolute",
    right: "20%",
    bottom: "8%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "& .desktop-image": {
      width: "80%",
    },
    "& .mobile-image": {
      width: "50%",
      position: "relative",
      right: 0,
      bottom: 0,
    },
  },
}));
