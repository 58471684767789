import React from "react";
import Banner from "../../../components/common/Banner";
import BannerImage from "../../../assets/image/AIBanner.png";
import { Stack, Box } from "@mui/material";
import image1 from "../../../assets/image/image1.png";
import image2 from "../../../assets/image/image2.png";
import image3 from "../../../assets/image/image3.png";
import image4 from "../../../assets/image/image4.png";
import image5 from "../../../assets/image/image5.png";
import image6 from "../../../assets/image/image6.png";
import image7 from "../../../assets/image/image7.png";
import WrapperContainer from "../../../components/common/WrapperContainer";
import CardLayoutB from "../../../components/common/CardLayoutB";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
const AIService = () => {

  const serviceData = [
    {
      title: "Custom AI Model Development ",
      description:
        "Our expert team builds AI models from the ground up, tailored to your specific business needs. Whether you need predictive analytics, automation, or intelligent systems, we develop models with high precision to drive better decision-making",
      points: [
        "Supervised, Unsupervised, and Reinforcement Learning Models ",
        "Natural Language Processing (NLP)",
        "Computer Vision ",
        "Deep Learning & Neural Networks ",
        "Time Series Forecasting ",
      ],
      imageUrl: image1,
    },
    {
      title: "AI-Powered Chatbots and Virtual Assistants",
      description:
        "Boost customer engagement with our AI-powered chatbots and virtual assistants. These intelligent systems offer seamless, human-like interactions, automating responses and elevating customer support",
      points: [
        "Customer Support Chatbots ",
        "Sales and Lead Generation Bots ",
        "eCommerce Chatbots for Product Recommendations ",
        "Virtual Assistants for Task Automation ",
        "Multilingual Bots for Global Businesses ",
      ],
      imageUrl: image2,
    },
    {
      title: "AI-Driven Data Analytics and Insights",
      description:
        "Transform your raw data into actionable insights using our AI-driven data analytics services. We provide advanced data mining techniques, predictive models, and visualization tools to help you make data-driven decisions",
      points: [
        "Predictive Analytics",
        "Big Data Processing",
        "Real-Time Data Analytics ",
        "entiment Analysis and Trend Prediction",
        "Customer Segmentation and Behavior Analysis ",
      ],
      imageUrl: image3,
    },
    {
      // title: "",
      title: " Computer Vision and Image Processing ",
      description:
        "Leverage the power of AI to analyze and interpret visual data through our computer vision solutions. Whether it’s object detection, image classification, or facial recognition, our solutions deliver high accuracy and efficiency. ",
      points: [
        "Image Recognition and Classification ",
        "Object Detection and Tracking ",
        "Facial Recognition System",
        "Optical Character Recognition (OCR)",
        "Video Analytics for Surveillance and Security ",
      ],
      imageUrl: image4,
    },
    {
      title: "Natural Language Processing (NLP) Solutions",
      description:
        "Our NLP solutions empower businesses to analyze, understand, and generate human language. From automating customer responses to content generation, our AI-driven NLP services cater to various business applications. ",
      points: [
        "Sentiment and Emotion Analysis",
        "Text Summarization and Extraction ",
        "Machine Translation",
        "Speech-to-Text and Text-to-Speech Systems ",
        "AI-Powered Content Creation",
      ],
      imageUrl: image5,
    },
    {
      title: "AI Automation and Process Optimization",
      description:
        "Our AI automation services streamline complex workflows and reduce manual intervention, leading to increased efficiency and cost savings. We integrate AI into your operations to optimize processes and maximize productivity",
      points: [
        "Robotic Process Automation (RPA)",
        "Workflow Automation for Manufacturing, HR, and Finance",
        "AI-Driven Supply Chain Optimization ",
        "Dynamic Pricing Strategies Based on Customer Behavior ",
        "Real-Time Personalization for eCommerce Platforms",
      ],
      imageUrl: image6,
    },
    {
      title: "Personalization and Recommendation Engines",
      description:
        "Deliver personalized experiences to your customers with our AI-powered recommendation engines. From product recommendations to personalized marketing strategies, we help businesses enhance engagement and customer satisfaction",
      points: [
        "Product and Content Recommendations ",
        "Personalized Marketing Campaigns",
        "Customer Segmentation for Targeted Advertising ",
        "Dynamic Pricing Strategies Based on Customer Behavior",
        "Real-Time Personalization for eCommerce Platforms",
      ],
      imageUrl: image7,
    },
    {
      title: "AI Consulting and Strategy",
      description:
        "Not sure where to start your AI journey? Our AI consulting services help you identify high-impact areas where AI can drive the most value. We guide you through the strategy, development, and implementation of AI solutions tailored to your business goals",
      points: [
        "AI Readiness Assessments ",
        "AI Strategy Development ",
        "AI Roadmap Planning",
        "Ethical AI and Governance ",
        "AI Education and Training for Teams ",
      ],
      imageUrl: image3,
    },
  ];
  const servicebanner = 
    {
      title: "Elevate Your Business with Cutting-Edge AI Engineering Services",
      subtitle:
        "Unlock the full potential of AI with our cutting-edge engineering services, designed to deliver tailored, transformative solutions that are innovative, powerful, and seamlessly scalable",
      BannerImage: BannerImage,
    }
  
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack>
      <Banner
        title={servicebanner.title}
        subtitle={servicebanner.subtitle}
        BannerImage={servicebanner.BannerImage}
      />
      <Box sx={{ padding: "5rem 0" }}>
        {serviceData.map((service, key) => (
          <Box
            sx={{
              // backgroundColor: key % 4 < 2 ? colors.lightgray : colors.white,
              marginTop:'5rem'
            }}
          >
            <WrapperContainer>
              <CardLayoutB
                key={key}
                service={service}
                isMdUp={isMdUp}
                isEven={key % 2 === 0}
              />
            </WrapperContainer>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default AIService;
