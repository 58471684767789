import styled from "@emotion/styled";
import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../utils/Colors/Colors";

const AnimationButton = ({
  innerColor = colors.black,
  textColor = colors.white,
  outerColor = colors.DarkOrange,
  title = "title",
  handleClick = () => null,
  ...props
}) => {
  return (
    <Animation
      outerColor={outerColor}
      innerColor={innerColor}
      textColor={textColor}
      {...props}
      onClick={handleClick}
    >
      <Text>{title}</Text>
    </Animation>
  );
};

const Animation = styled(ButtonBase)(
  ({ innerColor, textColor, outerColor }) => ({
    backgroundColor: innerColor,
    color: textColor,
    overflow: "hidden",
    padding: "10px 30px",
    borderRadius: 3,
    "&:hover::before": {
      width: "0%",
      transition: "all 0.3s ease-in-out",
    },
    "&::before": {
      position: "absolute",
      content: '" "',
      backgroundColor: outerColor,
      height: "50%",
      width: "100%",
      top: "0%",
      left: 0,
      transition: "all 0.3s ease-in",
      pointerEvents: "none",
      zIndex: 1,
    },
    "&:hover::after": {
      width: "0%",
      transition: "all 0.3s ease-in-out",
    },
    "&::after": {
      position: "absolute",
      content: '" "',
      backgroundColor: outerColor,
      height: "50%",
      width: "100%",
      top: "50%",
      right: 0,
      transition: "all 0.3s ease-in",
      pointerEvents: "none",
      zIndex: 1,
    },
  })
);

const Text = styled(Typography)(() => ({
  position: "relative",
  zIndex: 10,
}));

export default AnimationButton;
