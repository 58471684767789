export const colors = {
  DarkBlack: "#000000",
  TextBlack: "#454545",
  DarkOrange: "#E75300",
  green: "#00BC80",
  darkGreen: "#006B48",
  lightGreen2: "#45B68D",
  darkGreen2: "#022C12",
  default: "#F8F8F8",
  WillingGreen: "#00C283",
  littleGreen: "#E6F9F3",
  blue: "#384BFF",
  lightBlack: "#585858",
  Teal: "#038c96",
  yellow: "#F29F26",
  liteBlue: "#018FA4",
  grayBlue: "#366474",
  TimeText: "#14181F",
  littleBlue: "#009EFB1A",
  red: "#DA0000",
  littleRed: "#DA00000F",
  backdrop: "#0000003D",
  borderColor: "#D9D9D9",
  gray: "#959595",
  orange: "#FF8B00",
  littleOrange: "#FF8B001A",
  black: "#292929",
  white: "#FFFFFF",
  bgGray: "#F1F1F1",
  ratingColor: "#FFB300",
  skin: "#FFEEE9",
  violet: "#9E00BA",
  lightGreen: "#E6F9F380",
  littleViolet: "#9E00BA1A",
  gold: "#A37300",
  lightgray: "#EEEEEE",
  lightBlue: "#EBEDFF",
  text: {
    primary: "#000000",
    secondary: "#454545",
    tertiary: "#606067",
    quaternary: "#FFFFFF",
    orange: "#E8503A",
  },
  border: {
    primary: "#D9D9D9",
    secondary: "#DCDCDC",
  },
  background: {
    primary: "#FFFFFF",
    secondary: "#F8F8F8",
    tertiary: "#FDEEEB",
  },

  font: "OpenSans-Medium",
  fontBold: "OpenSans-Bold",
  fontSemiBold: "OpenSans-SemiBold",
  fontRegular: "OpenSans-Regular",
};
