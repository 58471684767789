import React from 'react'
import image1 from '../../assets/image/carzhencarosel1.png'
import image2 from '../../assets/image/carzhencarosel2.png'
import { Stack, Typography,Box } from '@mui/material'
import ReactDOM from 'react-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css'
const CarzhenSectionD = () => {
    const onChange = (index) => {
        console.log(`Carousel changed to index: ${index}`);
      };
    
      const onClickItem = (index) => {
        console.log(`Clicked on item: ${index}`);
      };
    
      const onClickThumb = (index) => {
        console.log(`Clicked on thumbnail: ${index}`);
      };
  return (
    <Stack sx={{ marginTop: '4rem', paddingBottom: '4rem' }}> {/* Add padding to the bottom */}
    <Typography sx={{ fontSize: '2rem', fontWeight: '600', textAlign: 'center' }}>
      A Glimpse into Our Platform
    </Typography>
    <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: '#3F3D4A', textAlign: 'center', marginTop: '1.5rem' }}>
      See how our intuitive design simplifies healthcare for patients, providers, and hospitals
    </Typography>
    <Box sx={{ width: '100%',marginTop: '3.65rem', overflow: 'hidden' }}>
      <Carousel
        showArrows={true}
        onChange={onChange}
        dynamicHeight
        onClickItem={onClickItem}
        onClickThumb={onClickThumb}
        showStatus={false}
        autoPlay={true} // Enable automatic scrolling
        interval={3000} // Set the time in milliseconds for each slide (e.g., 3000ms = 3 seconds)
        infiniteLoop={true} // Optionally hide the status text
      >
        <div>
          <img src={image1} alt="Legend 1" style={{ width: '60%', objectFit: 'contain',aspectRatio:'2/1' }} />
        </div>
        <div>
          <img src={image2} alt="Legend 2"  style={{ width: '60%', objectFit: 'contain',aspectRatio:'2/1' }} />
        </div>
      </Carousel>
    </Box>
  </Stack>
  
  )
}

export default CarzhenSectionD
 