import React from "react";

function Digital({ color = "#384BFF", width = 22, height = 18 }) {
  return (
    <svg
      width={width + ""}
      height={height + ""}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.056 16.515L7.144 10.365L10.434 13.515L14.455 10L16.695 12.184M21 2.5C21 2.10218 20.842 1.72064 20.5607 1.43934C20.2794 1.15804 19.8978 1 19.5 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V7H21V2.5Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 6V15.5C21 15.8978 20.842 16.2794 20.5607 16.5607C20.2794 16.842 19.8978 17 19.5 17H5M8.556 4H17.556M4.555 4H5.555M1 6V10.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Digital;
