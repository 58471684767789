import React from "react";
import { Button, CircularProgress } from "@mui/material";

/**
 * Custom Button component.
 * @param {React.ReactNode} children - The content of the button.
 * @param {string} variant - The variant of the button. Default is 'contained'.
 * @param {string} color - The color of the button. Default is 'primary'.
 * @param {string} size - The size of the button. Default is 'medium'.
 * @param {string|number} width - The width of the button.
 * @param {Object} props - Additional props to pass to the button.
 * @returns {JSX.Element}
 */
const CustomButton = ({
  children,
  variant = "contained",
  size = "large",
  fullWidth = true,
  type = "button",
  title = "button",
  loading = false,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      {...props}
      fullWidth={fullWidth}
      sx={{ backgroundColor: "#606FFF" }}
    >
      {loading ? (
        <CircularProgress size={24} color="background.paper" />
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
