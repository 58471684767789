import React from 'react';
import { Box, Typography, Avatar, Stack, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import WrapperContainer from '../../../components/common/WrapperContainer';
import CustomButton from '../../../components/common/CustomButton';

const BlogPostDetail = () => {

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

const location = useLocation()

  const description = location.state.description
  const title = location.state.title || ""
  const avatarColor = location.state.avatarColor || ""
  const date = location.state.date
  const name = location.state.name
  const image = location.state.image
  const role = location.state.role
  return (
   <>
    <WrapperContainer>
    <Box sx={{marginTop:'3em'}}>
      {/* Title Section */}
      <Typography variant="h4" component="h1" gutterBottom >
        {title}
      </Typography>

      {/* Author Info */}
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={avatarColor} alt={avatarColor} />
        <Box ml={2}>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {date}
          </Typography>
        </Box>
      </Box>

      {/* Main Image */}
      <Box
        component="img"
        src={image}
        alt="Main"
        sx={{
          // width: !isMdUp && '100%',
          height: isMdUp ? '400px' : '200px',
          borderRadius: "16px",
          mb: 2,
        }}
      />

      {/* Content Sections */}
        <stack sx={{ p: 2, mb: 2 }}>
          {/* <Typography variant="h6" gutterBottom>
            {title}
          </Typography> */}
          <Typography variant={isMdUp  ? "h5" : "h6"} fontWeight={400} fontFamily={'Kumbh Sans'} >
          <Stack dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </stack>
    </Box>
    </WrapperContainer>
    <Box sx={{ width: '100%', background: '#EBEDFF', padding: '3% 7%',display:'flex',flexDirection:'row',justifyContent:"space-between",marginBottom:'2rem' }}>
  {/* <Box display="flex" justifyContent="space-between" alignItems="center" sx={{paddingBottom:'2rem'}}> */}
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar src={avatarColor} alt={avatarColor} />
      <Box>
        <Typography sx={{ fontSize: '1.3rem', fontWeight: '600' }}>Written by {name}</Typography>
        <Typography sx={{ fontSize: '1rem', fontWeight: '500' }} color="text.secondary">
          {role}
        </Typography>
      </Box>
    </Stack>

    <Box>
      <CustomButton>Like</CustomButton>
    </Box>
  {/* </Box> */}
</Box>

   </>
  );
};

export default BlogPostDetail;
 