import { Typography, Stack, Box, Card,  useMediaQuery,
    useTheme, } from "@mui/material";
import React from "react";
import image1 from "../../assets/image/carzhencard1.png";
import image2 from "../../assets/image/carzhencard2.png";
import image3 from "../../assets/image/carzhencard3.png";
import image4 from "../../assets/image/carzhencard4.png";
 
const CarzhenSectionB = () => {
  const cardData = [
    {
      id: 1,
      image: image1,
      title: "Streamlined Patient Experience",
      description:
        "Enhance patient engagement through accessible scheduling, records, and secure communication.",
    },
    {
      id: 2,
      image: image2,
      title: "Efficient Provider Collaboration",
      description:
        "Share information, coordinate treatment plans, and monitor patient progress",
    },
    {
      id: 3,
      image: image3,
      title: "Unified Hospital Connectivity",
      description:
        " Integrate patient data and treatment plans across departments for comprehensive care",
    },
    {
      id: 4,
      image: image4,
      title: "Reminder for Your Appointments",
      description: "Reduce no-show rates and improve patient adherence",
    },
  ];
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack sx={{ marginBottom: "5rem" }}>
    <Typography
        sx={{
            fontWeight: "600",
            fontSize: "2rem",
            textAlign: "center",
            marginBottom: "3rem", // Adjusted for better spacing
        }}
    >
        Why Choose Our Healthcare Platform?
    </Typography>
    <Box 
        sx={{ 
            display: 'grid', 
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
            gap: '1.5rem' // Spacing between cards
        }}
    >
        {cardData.map(({ id, image, title, description }) => (
            <Box
                key={id}
                sx={{
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "24px",
                    display: 'flex',
                    flexDirection: 'column',
                    
                
                }}
            >
                <Box
                    sx={{ width: "5rem", height: "5rem", marginBottom: "1.625rem" }}
                >
                    <img src={image} width="100%" height="100%" alt={title} />
                </Box>

                <Typography
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: "600",
                        color: "#01636A",
                        marginBottom: "1rem",
                        
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{ fontSize: "1rem", fontWeight: "400", color: "#585858"  }}
                >
                    {description}
                </Typography>
            </Box>
        ))}
    </Box>
</Stack>
  );
};

export default CarzhenSectionB;
